import axios from 'axios'

const actions = {
  async SEND_TELEGRAM_MESSAGE(_, payload) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/notification/send`,
        payload
      )
    } catch (e) {
      console.log(e)
    }
  },
  async TEST_SEND({}, payload) {
    console.log('отправили АдМиНу')
    const req = {
      domen: payload.domen,
      message: payload.message,
    }
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/users/send-test`,
        req
      )
      console.log('response', response)
    } catch (e) {
      console.log(e)
    }
  },
  async SEND_WAITERS({}, payload) {
    console.log('отправили ОфИцИаНтУ')
    const req = {
      id: payload.id,
      message: payload.message,
    }
    console.log('req', req)
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/users/send-waiters`,
        req
      )
      console.log('response', response)
    } catch (e) {
      console.log(e)
    }
  },
  async CHANGE_RECIPIEND({}, payload) {
    const req = {
      response_template: payload.response_template,
      recipient: payload.recipient,
    }
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/notification/change-recipient`,
        req,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      console.log('|-response-|', response)
    } catch (e) {
      console.log(e)
    }
  },

  async SHOP_INFORMATION({}, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop-information`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      console.log('|-SHOP_INFORMATION-|', response)
      return response.data.result
    } catch (e) {
      console.log('E|-SHOP_INFORMATION-|E', e)
    }
  },
}

export default {
  namespaced: true,
  actions,
}
