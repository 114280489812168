import axios from 'axios'

const state = {
  groups: [],
}

const mutations = {
  SET_GROUPS(state, groups) {
    state.groups = groups
  },
}

const actions = {
  async CREATE_TELEGRAM({}, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/telegram`,
        payload.messenger,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async UPDATE_TELEGRAM({}, payload) {
    console.log('UPDATE_TELEGRAM', payload)
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/telegram`,
        payload.messenger,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async MAILING_TELEGRAM(_, payload) {
    const req = {
      message: payload.req.message,
      role: payload.req.role,
    }
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/telegram/mailing-list/send`,
        req,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response', response)
    } catch (e) {
      console.log('catch', e)
    }
  },
  async FETCH_GROUPS({ commit }, token) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/telegram/groups`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_GROUPS', res.data.result)
    } catch (e) {
      console.log(e)
    }
  },
  async DELETE_GROUP(_, payload) {
    const { id, token } = payload
    try {
      await axios.delete(
        `${process.env.VUE_APP_BASE_URL}/telegram/group/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async CREATE_GROUP(_, payload) {
    const { telegramId, token } = payload
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/telegram/group/create`,
        {
          telegram_id: telegramId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return response.data.result.id
    } catch (e) {
      console.log(e)
    }
  },
  async UPDATE_GROUP(_, payload) {
    const { telegramId, token, id } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/telegram/group/update`,
        {
          telegram_id: telegramId,
          id,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async NOTIFICATIONS(_, payload) {
    const { token, id, notifications } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/telegram/${id}/notifications`,
        {
          notifications,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async DELETE_WAITER(_, payload) {
    const { id, token } = payload
    try {
      await axios.delete(
        `${process.env.VUE_APP_BASE_URL}/telegram/contact/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_GROUPS(state) {
    return state.groups
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
