import axios from 'axios'

const actions = {
  async SAVE_PLUG(_, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/stub/set-stub`,
        {
          ids: payload.ids,
          type: payload.type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response', response)
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  actions,
}
