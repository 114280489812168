import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import LandingLayout from '@/views/layouts/landingLayout.vue'
import SignLayout from '@/views/layouts/signLayout.vue'
import ProfileLayout from '@/views/layouts/profileLayout.vue'
import EmptyLayout from '@/views/layouts/emptyLayout.vue'
import Echo from 'laravel-echo'
import DisableAutocomplete from '@aacassandra/vue-disable-autocomplete'
window.io = require('socket.io-client')

window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_SOCKET_URL,
})

// Plugins
import Maska from 'maska'
import VueScrollTo from 'vue-scrollto'
import VueCookies from 'vue3-cookies'
import vClickOutside from 'click-outside-vue3'

// Mixins
import titleMixin from '@/mixins/titleMixin'

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    const {
      config,
      response: { status, data },
    } = err
    console.log('interseptor status --> ', status)
    console.log('interseptor data --> ', data)
    if (data.message === 'Срок действия токена истек') {
      store.dispatch('user/REFRESH_TOKEN')
    }

    return Promise.reject(err)
  }
)

const app = createApp(App)

app.config.productionTip = false

// Components
app.component('LandingLayout', LandingLayout)
app.component('SignLayout', SignLayout)
app.component('ProfileLayout', ProfileLayout)
app.component('EmptyLayout', EmptyLayout)

// Mixins
app.mixin(titleMixin)

// App
app.use(store)
app.use(router)
app.use(Maska)
app.use(DisableAutocomplete)
app.use(VueCookies, {
  expireTimes: 10 * 365 * 24 * 60 * 60,
})
app.use(VueScrollTo, { duration: 1500 })
app.use(vClickOutside)
app.mount('#app')
