import axios from 'axios'

const state = () => ({
  politicsError: false,
})

const mutations = {
  SET_POLITICS_ERRORS(state, payload) {
    state.politicsError = payload
  },
}

const actions = {
  async FETCH_NOT_FAMILIRIZATION_DOCUMENTS(_, token) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/legal-document/list-not-familiarization-document`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return res.data.result
    } catch (e) {
      console.log('====================================')
      console.log(
        'FETCH_NOT_FAMILIRIZATION_DOCUMENTS_ERROR',
        JSON.stringify(e.response.data, null, 2)
      )
      console.log('====================================')
    }
  },

  async CONFIRM_NOT_FAMILIRIZATION_DOCUMENTS(_, payload) {
    const { token, code } = payload
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/legal-document/mark-confirmed-document`,
        { document_code: code },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return res.data
    } catch (e) {
      console.log('====================================')
      console.log(
        'CONFIRM_NOT_FAMILIRIZATION_DOCUMENTS_ERROR',
        JSON.stringify(e.response.data, null, 2)
      )
      console.log('====================================')
    }
  },

  TOGGLE_POLITICS_ERROR({ commit }, payload) {
    commit('SET_POLITICS_ERRORS', payload)
  },
}

const getters = {
  GET_POLITICS_ERROR(state) {
    return state.politicsError
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
