<template>
  <header
    id="header"
    class="vLandingHeader"
  >
    <div class="vLandingHeader__container">
      <img
        src="@/assets/images/landing_logo.svg"
        alt="Логотип Tapper"
        class="vLandingHeader__logo"
      >
      <nav class="vLandingHeader__nav">
        <ul class="vLandingHeader__nav-list">
          <li class="vLandingHeader__nav-item">
            <a
              v-scroll-to="'#howwork'"
              href="#"
              class="vLandingHeader__nav-link"
            >Как это работает?</a>
          </li>
          <li class="vLandingHeader__nav-item">
            <a
              v-scroll-to="'#posibilities'"
              href="#"
              class="vLandingHeader__nav-link"
            >Возможности</a>
          </li>
          <li class="vLandingHeader__nav-item">
            <a
              v-scroll-to="'#questions'"
              href="#"
              class="vLandingHeader__nav-link"
            >Вопрос-ответ</a>
          </li>
          <li class="vLandingHeader__nav-item">
            <a
              v-scroll-to="'#footer'"
              href="#"
              class="vLandingHeader__nav-link"
            >Контакты</a>
          </li>
        </ul>
      </nav>
      <div class="vLandingHeader__login">
        <a
          href="tel:88005006768"
          class="vLandingHeader__phone"
        >8(800) 500-67-68</a>
        <router-link
          v-if="GET_AUTHENTIFICATED !== null"
          to="/profile"
          class="vLandingHeader__login-link"
        >
          Профиль
        </router-link>
        <router-link
          v-else
          to="/users"
          class="vLandingHeader__login-link"
        >
          Войти
        </router-link>
      </div>
      <div
        v-if="!menuStatus"
        class="vLandingHeader__menu-icon"
        @click="toggleMenu"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.5 12H18.5"
            stroke="#FBFBFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.5 16H18.5"
            stroke="#FBFBFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.5 8H10.5"
            stroke="#FBFBFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        v-else
        aria-label="Закрыть окно"
        class="vLandingHeader__close-menu"
        @click="toggleMenu"
      />
    </div>
    <vLandingMenu
      :status="menuStatus"
      @toggleMenu="toggleMenu"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import vLandingMenu from '@/components/landing/v-landing-menu'

export default {
  name: 'VLandingHeader',
  components: {
    vLandingMenu,
  },
  data() {
    return {
      menuStatus: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  methods: {
    toggleMenu() {
      this.menuStatus = !this.menuStatus
    },
  },
}
</script>

<style lang="scss">
.vLandingHeader {
  position: relative;
  padding: 30px 0;
  color: #fbfbfc;
  background: linear-gradient(94.19deg, #14141b 48.83%, #0c0c10 78.63%);
  @media (max-width: 769px) {
    padding: 19px 0;
    border-bottom: 1px solid #2c3038;
  }
  &__container {
    width: 100%;
    max-width: 1200px;
    padding: 0 36px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 769px) {
      padding: 0 20px;
    }
  }
  &__nav {
    @media (max-width: 769px) {
      display: none;
    }
  }
  &__login {
    @media (max-width: 769px) {
      display: none;
    }
  }
  &__logo {
    width: 104px;
    height: 40px;
    @media (max-width: 991px) {
      width: 90px;
      height: 32px;
      margin-right: 10px;
    }
    @media (max-width: 769px) {
      width: 72px;
      height: 28px;
    }
  }
  &__nav-list {
    display: flex;
  }
  &__nav-item:not(:last-child) {
    margin-right: 24px;
    @media (max-width: 991px) {
      margin-right: 10px;
    }
  }
  &__nav-link {
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fbfbfc;
    text-decoration: none;
    @media (max-width: 1023px) {
      font-size: 14px;
    }
    @media (max-width: 991px) {
      font-size: 12px;
    }
  }
  &__phone {
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.55px;
    color: #fbfbfc;
    text-decoration: none;
    margin-right: 23px;
    margin-bottom: 5px;
    @media (max-width: 991px) {
      margin-right: 10px;
      font-size: 18px;
    }
  }
  &__login-link {
    display: inline-block;
    padding: 16px 43px;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fbfbfc;
    border: 2px solid #9c1313;
    border-radius: 8px;
    text-decoration: none;
    &:hover {
      background-color: #e01b1c;
      border: 2px solid #9c1313;
    }
    @media (max-width: 991px) {
      padding: 12px 15px;
      font-size: 14px;
    }
  }
  &__menu-icon {
    display: none;
    cursor: pointer;
    @media (max-width: 769px) {
      display: inline-block;
    }
  }
  &__close-menu {
    position: relative;
    width: 24px;
    height: 24px;
    background: #fbfbfc;
    cursor: pointer;
    border-radius: 50%;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      height: 2px;
      width: 10px;
      background: #c01718;
    }
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 2px;
      width: 10px;
      background: #c01718;
    }
  }
}
</style>
