<template>
  <div class="vLandingPoliticModal vLandingPoliticModal--pay">
    <div
      class="vLandingPoliticModal__overlay"
      @click="togglePayModal"
    />
    <div class="vLandingPoliticModal__content">
      <h2 class="vLandingPoliticModal__title">
        БЕЗОПАСНОСТЬ ПЛАТЕЖЕЙ
      </h2>
      <div
        class="vLandingPoliticModal__block vLandingPoliticModal__block-images"
      >
        <img
          src="@/assets/images/best2pay.png"
          alt="Логотип Best2Pay"
          class="best2pay"
        >
        <img
          src="@/assets/images/visa.png"
          alt="Логотип Visa"
          class="visa"
        >
        <img
          src="@/assets/images/mastercard.png"
          alt="Логотип Mastercard"
          class="mastercard"
        >
        <img
          src="@/assets/images/mir.jpg"
          alt="Логотип Мир"
          class="mir"
        >
      </div>
      <p class="vLandingPoliticModal__text">
        Оплатить заказ можно с помощью банковских карт платёжных систем Visa,
        MasterCard, МИР. При оплате банковской картой безопасность платежей
        гарантирует процессинговый центр
        <a
          href="https://best2pay.net/"
          target="_blank"
        >Best2Pay</a>.
      </p>
      <p class="vLandingPoliticModal__text">
        Приём платежей происходит через защищённое безопасное соединение,
        используя протокол TLS 1.2. Компания
        <a
          href="https://best2pay.net/"
          target="_blank"
        >Best2Pay</a>
        соответствует международным требованиями PCI DSS для обеспечения
        безопасной обработки реквизитов банковской карты плательщика. Ваши
        конфиденциальные данные необходимые для оплаты (реквизиты карты,
        регистрационные данные и др.) не поступают в сервис Tapper, их обработка
        производится на стороне процессингового центра
        <a
          href="https://best2pay.net/"
          target="_blank"
        >Best2Pay</a> и полностью
        защищена. Никто, в том числе сервис Tapper
        <a
          href="https://tapper.cloud/"
          target="_blank"
        >https://tapper.cloud/</a>, не может получить банковские и персональные данные плательщика.
      </p>
      <p class="vLandingPoliticModal__text">
        При оплате заказа банковской картой возврат денежных средств
        производится на ту же самую карту, с которой был произведён платёж.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VLandingPoliticModal',
  emits: ['togglePayModal'],
  methods: {
    togglePayModal() {
      this.$emit('togglePayModal')
    },
  },
}
</script>
<style lang="scss">
.vLandingPoliticModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #212129;
    opacity: 0.95;
    z-index: 15;
  }
  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 280px;
    width: 100%;
    max-width: 750px;
    padding: 24px 24px;
    max-height: 100vh;
    font-family: 'PTRootUI';
    font-size: 16px;
    line-height: 20px;
    z-index: 20;
    transform: translate(-50%, -50%);
    background-color: #fff;
    overflow-y: auto;
    scrollbar-color: #cacaca #fff !important;
    @media (max-width: 769px) {
      width: 80%;
      height: 80%;
      background-color: #fff;
    }
  }
  &__title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Stolzl';
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  &__block {
    margin-bottom: 20px;
  }
  &__block-images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  img:not(:last-child) {
    margin-right: 15px;
  }
  .best2pay {
    width: 169px;
  }
  .visa {
    width: 117px;
  }
  .mastercard {
    width: 60px;
  }
  .mir {
    width: 130px;
  }
  &--pay {
    .vLandingPoliticModal__text {
      margin-bottom: 16px;
    }
  }
}
</style>
