<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M18.9539 21H5.0459C3.9409 21 3.0459 20.105 3.0459 19V8C3.0459 6.895 3.9409 6 5.0459 6H18.9539C20.0589 6 20.9539 6.895 20.9539 8V19C20.9539 20.105 20.0579 21 18.9539 21Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5002 18C16.3692 17.671 16.1662 17.375 15.9062 17.134C15.4672 16.726 14.8922 16.5 14.2922 16.5C13.2922 16.5 10.7082 16.5 9.70823 16.5C9.10823 16.5 8.53423 16.727 8.09423 17.134C7.83423 17.375 7.63123 17.671 7.50023 18M4.78223 6V5C4.78223 3.895 5.67723 3 6.78223 3H17.2172C18.3222 3 19.2172 3.895 19.2172 5V6M12.0002 9C13.3812 9 14.5002 10.119 14.5002 11.5C14.5002 12.881 13.3812 14 12.0002 14C10.6192 14 9.50023 12.881 9.50023 11.5C9.50023 10.119 10.6192 9 12.0002 9Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconWaiter',
}
</script>
