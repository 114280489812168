<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico svgFill"
  >
    <path
      d="M8.02627 18.633C7.01227 18.633 6.09727 18.468 5.28127 18.138C4.47127 17.808 3.78127 17.346 3.21127 16.752C2.64127 16.152 2.20327 15.45 1.89727 14.646C1.59727 13.836 1.44727 12.954 1.44727 12C1.44727 10.704 1.71427 9.558 2.24827 8.562C2.78227 7.56 3.52327 6.777 4.47127 6.213C5.41927 5.649 6.52327 5.367 7.78327 5.367C8.20927 5.367 8.62327 5.397 9.02527 5.457C9.42727 5.517 9.80827 5.598 10.1683 5.7C10.5283 5.802 10.8613 5.913 11.1673 6.033C11.4733 6.153 11.7373 6.276 11.9593 6.402L11.8153 7.968C11.3473 7.692 10.8913 7.47 10.4473 7.302C10.0093 7.128 9.57727 7.002 9.15127 6.924C8.73127 6.846 8.32027 6.807 7.91827 6.807C6.99427 6.807 6.17227 7.017 5.45227 7.437C4.73227 7.857 4.16527 8.457 3.75127 9.237C3.34327 10.011 3.13927 10.935 3.13927 12.009C3.13927 12.723 3.24427 13.395 3.45427 14.025C3.67027 14.655 3.98827 15.21 4.40827 15.69C4.83427 16.17 5.35927 16.548 5.98327 16.824C6.61327 17.094 7.34527 17.229 8.17927 17.229C8.48527 17.229 8.79127 17.211 9.09727 17.175C9.40327 17.133 9.70627 17.07 10.0063 16.986C10.3063 16.902 10.6003 16.791 10.8883 16.653C11.1823 16.509 11.4703 16.341 11.7523 16.149L11.8783 17.697C11.6263 17.859 11.3473 18 11.0413 18.12C10.7413 18.24 10.4233 18.339 10.0873 18.417C9.75727 18.489 9.41827 18.543 9.07027 18.579C8.72227 18.615 8.37427 18.633 8.02627 18.633Z"
    />
    <path
      d="M14.5345 18.435C14.5345 17.721 14.5345 17.034 14.5345 16.374C14.5345 15.714 14.5345 14.988 14.5345 14.196V9.894C14.5345 9.084 14.5345 8.346 14.5345 7.68C14.5345 7.008 14.5345 6.315 14.5345 5.601H16.1635C16.1635 6.315 16.1635 7.008 16.1635 7.68C16.1635 8.346 16.1635 9.084 16.1635 9.894V13.944C16.1635 14.736 16.1635 15.462 16.1635 16.122C16.1635 16.782 16.1635 17.469 16.1635 18.183L15.5515 16.986H17.9095C18.5935 16.986 19.1965 16.986 19.7185 16.986C20.2465 16.986 20.7325 16.986 21.1765 16.986C21.6265 16.986 22.0855 16.986 22.5535 16.986V18.435H14.5345Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconCloudCard',
}
</script>

<style scoped></style>
