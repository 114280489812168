import renameDublicateArrayElements from '@/utils/renameDublicateArrayElement'
import axios from 'axios'

const state = {
  categories: [],
  adminMenuTest: [],
  visibleMenu: 1,
}

const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_ADMIN_MENU_TEST(state, adminMenuTest) {
    state.adminMenuTest = adminMenuTest
  },
  SET_VISIBLE_MENU(state, status) {
    state.visibleMenu = status
  },
}

const actions = {
  // Редактирование категори
  async EDIT_CATEGORY({ commit, state }, payload) {
    let copyCategories = state.categories
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/update-category/${payload.id}`,
        {
          our_name: payload.our_name,
          list_position: payload.list_position,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      copyCategories.forEach((i) => {
        if (payload.id === i.id) {
          i.our_name = payload.our_name
        }
        if (i.our_name === '-') {
          i.our_name = null
        }
      })
      commit('SET_CATEGORIES', copyCategories)
    } catch (e) {
      console.log(e)
    }
  },

  // Очистить категории
  CLEAR_CATEGORIES({ commit }) {
    commit('SET_CATEGORIES', [])
  },

  // Получить все меню
  async FETCH_MENU({ commit }, payload) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/menu/get-menu`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      console.log('FETCH_MENU response.data', response)

      if (response) {
        if (response.data.data.status === 'loading') {
          return response.data.data.percent_loaded
            ? response.data.data.percent_loaded
            : 0
        } else {
          const categories = response.data.data.categories
          const status = response.data.data.visible
          const resultTemp = []
          const result = []

          categories.forEach((i) => {
            if (i.products.length) {
              resultTemp.push(i)
            }
          })

          let orderCategoryCount = 0
          let orderProductCount = 0

          resultTemp.forEach((i) => {
            const products = []
            if (!i.hidden) orderCategoryCount++
            orderProductCount = 0
            i.products.forEach((j) => {
              if (!j.hidden) orderProductCount++
              products.push({
                ...j,
                order: !j.hidden ? orderProductCount : null,
                nameСheckout: j.name,
                ourName:
                  j.our_name !== 'null' && j.our_name !== '-' ? j.our_name : '',
                editContent: false,
                hideOnOff: true,
                category: i.name,
                gram: i.gram ? i.gram : null,
              })
            })
            result.push({
              ...i,
              our_name:
                i.our_name !== 'null' && i.our_name !== '-' ? i.our_name : '',
              order: !i.hidden ? orderCategoryCount : null,
              products: [...products],
            })
          })

          const categoryArrayNames = result.map((i) => i.name)

          const filteredArray = renameDublicateArrayElements(categoryArrayNames)

          result.forEach((i, index) => {
            i.name = filteredArray[index]
          })

          commit('SET_CATEGORIES', result)
          commit('SET_VISIBLE_MENU', status)
          return 100
        }
      }
    } catch (e) {
      console.log(e)
      return 'error'
    }
  },

  // Сортировка категорй
  async CATEGORY_SORT({ state, dispatch }, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/update-category/${payload.id}`,
        {
          our_name: payload.ourName ? payload.ourName : '-',
          list_position: payload.listPosition,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      dispatch('CLEAR_CATEGORIES')
      await dispatch('FETCH_MENU', { token: payload.token })
    } catch (e) {
      console.log(e)
    }
  },

  // Переключить видимость категории
  async CATEGORY_VISIBLE({ commit, state }, payload) {
    console.log('CATEGORY_VISIBLE', payload)
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/category-visible/${payload.id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      let orderCategoryCount = 0
      const copyCategories = [...state.categories]
      copyCategories.forEach((i) => {
        if (i.id === payload.id) {
          i.hidden = i.hidden ? 0 : 1
        }
        if (!i.hidden) {
          orderCategoryCount++
          i.order = orderCategoryCount
        } else {
          i.order = null
        }
      })

      commit('SET_CATEGORIES', copyCategories)
    } catch (e) {
      console.log(e)
    }
  },

  // Переключить видимость продукта
  async PRODUCT_VISIBLE({ commit, state }, payload) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/product-visible/${payload.id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      let orderProductCount = 0
      const copyCategories = [...state.categories]
      copyCategories.forEach((i) => {
        orderProductCount = 0
        i.products.forEach((j) => {
          if (j.id === payload.id) {
            j.hidden = j.hidden ? 0 : 1
          }
          if (!j.hidden) {
            orderProductCount++
            j.order = orderProductCount
          } else {
            j.order = null
          }
        })
      })

      commit('SET_CATEGORIES', copyCategories)
    } catch (e) {
      console.log(e)
    }
  },

  // Сортировка продукта
  async PRODUCT_SORT({ dispatch }, payload) {
    const newPayload = { ...payload }
    delete newPayload.product_picture
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/menu/product-update/${payload.id}`,
        newPayload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      dispatch('CLEAR_CATEGORIES')
      await dispatch('FETCH_MENU', { token: payload.token })
    } catch (e) {
      console.log(e)
    }
  },

  // Обновление продукта
  async PRODUCT_UPDATE({ commit, state }, payload) {
    try {
      const formData = new FormData()
      if (payload.product_picture) {
        formData.append('product_picture', payload.product_picture)

        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/menu/product-update/${payload.id}`,
          formData,
          {
            headers: {
              Authorization: 'Bearer ' + payload.token,
            },
          }
        )
      }

      delete payload.product_picture

      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/menu/product-update/${payload.id}`,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const copyCategories = [...state.categories]
      await copyCategories.forEach((i) => {
        i.products.forEach((j) => {
          if (j.id === payload.id) {
            j.ourName =
              response.data.our_name !== 'null' && response.data.our_name
            if (response.data.media.length) {
              j.product_picture = {
                versions: {
                  optimized:
                    response.data.media.length &&
                    response.data.media[0].original_url,
                },
              }
            }
            if (payload.delete_picture) {
              j.product_picture = null
            }
          }
        })
      })
      await commit('SET_CATEGORIES', copyCategories)
    } catch (e) {
      console.log('====================================')
      console.log('PRODUCT_UPDATE__ERROR', JSON.stringify(e.response, null, 2))
      console.log('====================================')
      return Promise.reject(e.response.data.errors)
    }
  },

  // Получение меню для пользоваеля
  async FETCH_MENU_USER({ commit }, urlName) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/menu/get-menu-user/${urlName}`
      )
      const result = []
      if (response.data.data.visible) {
        if (response.data.data.status !== 'disabled') {
          response.data.data.categories.forEach((i) => {
            //TODO: Выпилить

            const productsWithPicture = []
            const productsWithotPicture = []
            if (i.products.length) {
              i.products.forEach((j) => {
                if (j.product_picture) {
                  productsWithPicture.push(j)
                } else {
                  productsWithotPicture.push(j)
                }
              })
            }

            if (!i.hidden) {
              result.push({
                ...i,
              })
            }
          })
          console.log(result)
        }
        // response.data.data.categories.forEach()
        commit('SET_ADMIN_MENU_TEST', result)
      }
      console.log('response menuUpdate', response)
    } catch (e) {
      console.log(e)
    }
  },

  // Видимость меню
  async MENU_VISIBLE({ commit, state }, payload) {
    console.log('MENU_VISIBLE')
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/menu-visible/${payload.domen}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_VISIBLE_MENU', state.visibleMenu === 1 ? 0 : 1)
      console.log('response MENU_VISIBLE', response)
    } catch (e) {
      console.log(e)
    }
  },

  // Обновление меню
  async MENU_UPDATE({}, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/menu/menu-update/${payload.shop_id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response MENU_UPDATE', response)
      if (response.data.success) {
        return true
      }
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_CATEGORIES(state) {
    return state.categories
  },
  GET_ADMIN_MENU_TEST(state) {
    return state.adminMenuTest
  },
  GET_PRODUCTS(state) {
    return state.products
  },
  GET_VISIBLE_MENU(state) {
    return state.visibleMenu
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
