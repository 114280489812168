<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M9.999 7C9.999 8.105 8.432 9 6.499 9C4.566 9 3 8.104 3 7M3 4C3 5.105 4.567 6 6.5 6C8.433 6 10 5.105 10 4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.999 4C10 2.895 8.432 2 6.5 2C4.568 2 3.001 2.896 3 4V10C3 11.104 4.566 12 6.499 12C8.432 12 9.999 11.105 9.999 10V4ZM19.545 16.091H14.454C13.098 16.091 11.999 17.19 11.999 18.546C12 19.901 13.099 21 14.455 21H19.546C20.901 21 22 19.901 22 18.545C22 17.19 20.901 16.091 19.545 16.091Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.507 18.51C14.5207 18.51 14.4223 18.5343 14.436 18.51C14.4289 18.5171 14.4223 18.5343 14.4223 18.5343H14.5207C14.5186 18.5255 14.5141 18.5171 14.507 18.51Z"
      fill="#C4C4C4"
    />
    <path
      d="M14.436 18.581C14.5 18.5 14.487 18.601 14.507 18.581C14.5199 18.5681 14.3359 18.5343 14.5207 18.5343H14.4223C14.4223 18.5343 14.4231 18.5681 14.436 18.581Z"
      fill="#C4C4C4"
    />
    <path
      d="M21.992 18.347L21.643 13.846C21.562 12.804 20.693 12 19.648 12H14.351C13.306 12 12.438 12.804 12.357 13.845L12.008 18.346M15.997 3H19.283C20.229 3 20.997 3.768 20.997 4.714V8M8 21H4.714C3.768 21 3 20.232 3 19.286V16M14.4223 18.5343C14.4223 18.5343 14.4231 18.5681 14.436 18.581C14.5 18.5 14.487 18.601 14.507 18.581C14.5199 18.5681 14.3359 18.5343 14.5207 18.5343M14.4223 18.5343C14.4223 18.5343 14.4289 18.5171 14.436 18.51C14.4223 18.5343 14.5207 18.51 14.507 18.51C14.5141 18.5171 14.5186 18.5255 14.5207 18.5343M14.4223 18.5343H14.5207"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconIntegration',
}
</script>
