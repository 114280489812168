const state = {
  systemSelection: null,
}

const mutations = {
  SET_SELECTED_SYSTEM(state, payload) {
    state.systemSelection = payload
  },
}
// const actions = {
//   CHAANGE_ACTIVE_PROFILE_MENU ({ commit }, payload) {
//     commit('SET_MENU_PROFILE', payload)
//   }
// }

const getters = {
  GET_SELECTED_SYSTEM(state) {
    return state.systemSelection
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  // actions,
  getters,
}
