<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M5.55796 9.76295L5.27096 8.69596C4.98196 7.62696 5.61696 6.52795 6.68696 6.24295L16.73 3.56695C17.79 3.28495 18.89 3.91996 19.176 4.97896L20.93 11.488C21.218 12.557 20.582 13.656 19.514 13.941L17.4 14.504"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 13.8399H17.4M5 20.4999H15.4C16.505 20.4999 17.4 19.6049 17.4 18.4999V11.7629C17.4 10.6579 16.505 9.76294 15.4 9.76294H5C3.895 9.76294 3 10.6579 3 11.7629V18.4999C3 19.6049 3.896 20.4999 5 20.4999V20.4999Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconHistory',
}
</script>
<style lang="scss"></style>
