import axios from 'axios'

const actions = {
  async FETCH_LOYALTY(_, payload) {
    const { token, id } = payload
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/restaurant/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return response.data.success
        ? response.data.result.loyalty_system
        : response.data.success
    } catch (e) {
      console.log(e)
    }
  },
  async SAVE_LOYALTY(_, payload) {
    const { token, id, loyalty } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/restaurant/change-loyalty-system/${id}`,
        { loyalty_system: loyalty },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  actions,
}
