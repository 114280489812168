<template>
  <div
    class="vLandingMenu"
    :class="{ active: status }"
  >
    <nav class="vLandingMenu__nav">
      <ul class="vLandingMenu__list">
        <li class="vLandingMenu__item">
          <a
            v-scroll-to="'#howwork'"
            href="#"
            class="vLandingMenu__link"
            @click="toggleMenu"
          >Как это работает?</a>
        </li>
        <li class="vLandingMenu__item">
          <a
            v-scroll-to="'#posibilities'"
            href="#"
            class="vLandingMenu__link"
            @click="toggleMenu"
          >Возможности</a>
        </li>
        <li class="vLandingMenu__item">
          <a
            v-scroll-to="'#questions'"
            href="#"
            class="vLandingMenu__link"
            @click="toggleMenu"
          >Вопрос-ответ</a>
        </li>
        <li class="vLandingMenu__item">
          <a
            v-scroll-to="'#footer'"
            href="#footer"
            class="vLandingMenu__link"
            @click="toggleMenu"
          >Контакты</a>
        </li>
      </ul>
    </nav>
    <router-link
      v-if="GET_AUTHENTIFICATED !== null"
      to="/profile"
      class="vLandingMenu__login-link"
    >
      Профиль
    </router-link>
    <router-link
      v-else
      to="/users"
      class="vLandingMenu__login-link"
    >
      Войти
    </router-link>
    <a
      href="tel:88005006768"
      class="vLandingMenu__menu-phone"
    >8(800) 500-67-68</a>
    <a
      href="mailto:info@tapper.cloud"
      class="vLandingMenu__menu-mail"
    >info@tapper.cloud</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VLandingMenu',
  props: {
    status: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  emits: ['toggleMenu'],
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu')
    },
  },
}
</script>

<style lang="scss">
@keyframes toggleMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.vLandingMenu {
  display: none;
  position: absolute;
  top: 67px;
  left: 0;
  width: 100%;
  height: calc(100vh - 67px);
  overflow-y: auto;
  padding: 32px 20px;
  background: linear-gradient(78.76deg, #14141b 0%, #23232c 100%), #ffffff;
  z-index: 9;
  &.active {
    display: block;
    animation: toggleMenu 0.3s ease-in-out;
  }
  &__nav {
    margin-bottom: 24px;
    border-bottom: 1px solid #2c3038;
  }
  &__item {
    margin-bottom: 24px;
  }
  &__link {
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #e4e4eb;
    text-decoration: none;
  }
  &__login-link {
    display: block;
    padding: 16px 43px;
    margin-bottom: 20px;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #fbfbfc;
    border: 2px solid #9c1313;
    border-radius: 8px;
    text-decoration: none;
  }
  &__menu-phone {
    display: block;
    margin-bottom: 20px;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.55px;
    color: #fbfbfc;
    text-decoration: none;
  }
  &__menu-mail {
    display: block;
    margin-bottom: 20px;
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #fbfbfc;
    text-decoration: none;
  }
}
</style>
