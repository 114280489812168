<template>
  <div class="vProfileMobileMenu">
    <div
      class="vProfileMobileMenu__main"
      @click="CHAANGE_ACTIVE_PROFILE_MENU(null)"
    >
      <img src="@/assets/images/menu-icon.svg">
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'VProfileMobileMenu',
  methods: {
    ...mapActions('menu', ['CHAANGE_ACTIVE_PROFILE_MENU']),
  },
}
</script>
<style lang="scss">
.vProfileMobileMenu {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 62px;
  border-top: 1px solid #cccfd7;
  background-color: #fff;
  z-index: 99;
  @media (max-width: 769px) {
    display: flex;
  }
  &__main {
    position: absolute;
    left: 50%;
    top: -50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 100px;
    border-top: 1px solid #f7f8fa;
    border-left: 1px solid #f7f8fa;
    border-bottom: 1px solid #cccfd7;
    border-right: 1px solid #cccfd7;
    background-color: #f7f8fa;
    transform: translateX(-50%) rotate(45deg);
    img {
      transform: rotate(-45deg);
    }
  }
}
</style>
