<template>
  <main class="profileLayout">
    <vProfileHeader />
    <vProfileMenu />
    <slot />
    <vPreloader v-if="PRELOADER" />
    <vProfileMobileMenu />
    <vAuthentication />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vProfileHeader from '@/components/profile/v-profile-header'
import vProfileMenu from '@/components/profile/v-profile-menu'
import vProfileMobileMenu from '@/components/profile/v-profile-mobile-menu'
import vPreloader from '@/components/v-preloader'
import vAuthentication from '@/components/v-authentication'
export default {
  name: 'ProfileLayout',
  components: {
    vProfileHeader,
    vProfileMenu,
    vAuthentication,
    vPreloader,
    vProfileMobileMenu,
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
    ...mapGetters('preloader', ['PRELOADER']),
  },
  mounted() {
    this.GET_USER_DATA(this.GET_AUTHENTIFICATED)
    if (window.innerWidth < 769) {
      setTimeout(() => {
        this.TOGGLE_PRELOADER(false)
      }, 500)
    }
  },
  methods: {
    ...mapActions('user', ['GET_USER_DATA']),
    ...mapActions('menu', ['CHAANGE_ACTIVE_PROFILE_MENU']),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    selectSection(num) {
      this.activeSection = num
    },
  },
}
</script>

<style lang="scss">
.profileLayout {
  padding-bottom: 20px;
  position: relative;
  width: 100%;
  max-width: none;
  min-height: 100vh;
  border-radius: 0;
  margin: 0;
  display: block;
  background: #f8f9fd;
  padding-left: 72px;
  font-family: 'Commissioner';
  @media (max-width: 769px) {
    padding-left: 0;
    min-height: auto;
    height: 100vh;
    padding-bottom: 0;
  }
}
</style>
