import axios from 'axios'
const state = {
  qr: null,
  tables: [],
  restaurants: [],
  restaurants_pages: null,
  selectRest: null,
  selectedTable: null,
}

const mutations = {
  SET_QR(state, qr) {
    state.qr = qr
  },
  SET_TABLES(state, tables) {
    state.tables = tables
  },
  SET_RESTAURANTS(state, restaurants) {
    state.restaurants = restaurants
  },
  SET_RESTAURANTS_PAGES(state, restaurants_pages) {
    state.restaurants_pages = restaurants_pages
  },
  SET_SELECT_REST(state, data) {
    state.selectRest = data
  },
  SET_CURRENT_TABLE(state, payload) {
    state.selectedTable = payload
  },
}
const actions = {
  async FETCH_INFO_RESTAURANT(_, payload) {
    const { token, id } = payload
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/restaurant/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      console.log('FETCH_INFO_RESTAURANT', response)
    } catch (e) {
      console.log(e)
    }
  },
  SELECT_REST({ commit }, rest) {
    commit('SET_SELECT_REST', rest)
  },
  async FETCH_RESTAURANTS({ commit }, payload) {
    const { token, search } = payload
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/shops?search=${search}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )

      // const data = {
      //   current_page: response.data.result.current_page,
      //   last_page: response.data.result.last_page,
      // }
      let restArr = []
      restArr = [...response.data.result]

      console.log('restArr1', restArr)

      // if (response.data.result.last_page > response.data.result.current_page) {
      //   for (let i = 1; i < response.data.result.last_page; i++) {
      //     const response2 = await axios.get(
      //       `${process.env.VUE_APP_BASE_URL}/support/shops?page=${i + 1}`,
      //       {
      //         headers: {
      //           Authorization: 'Bearer ' + token,
      //         },
      //       }
      //     )
      //     const oldRestArr = [...restArr]
      //     restArr = [...oldRestArr, ...response2.data.result.data]
      //     console.log('restArr2', response2.data.result.data)
      //   }
      // }

      // commit('SET_RESTAURANTS_PAGES', data)
      commit('SET_RESTAURANTS', restArr)
    } catch (e) {
      console.log(e)
    }
  },

  CHANGE_TIPS({ commit }, rest) {
    commit('SET_SELECT_REST', rest)
  },

  CHANGE_PLATFORM_REST({ commit, state }, payload) {
    const copyRests = state.restaurants
    copyRests.forEach((i) => {
      if (i.id == payload.id) {
        i.platforms_id = payload.platformsId
        i.external_id = payload.externalId
      }
    })
    commit('SET_RESTAURANTS', copyRests)
  },

  async SHOW_MORE_RESTAURANTS({ commit, state }, { token, page }) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/shops?page=${page}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      const newArray = [...state.restaurants, ...response.data.result.data]
      const data = {
        current_page: response.data.result.current_page,
        last_page: response.data.result.last_page,
      }
      commit('SET_RESTAURANTS_PAGES', data)
      commit('SET_RESTAURANTS', newArray)
    } catch (e) {
      console.log(e)
    }
  },

  async FETCH_SELECTED_TABLE({ commit }, payload) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/handbook/table/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('FETCH_QR_FOR_TABLE', res)
      commit('SET_QR', {
        qr: res.data.table.qr,
        qrInvert: res.data.table.qr_invert,
      })
      commit('SET_CURRENT_TABLE', res.data)
    } catch (e) {
      console.log(e)
    }
  },

  async FETCH_TABLES({ commit }, payload) {
    const { page, range_from, range_to, shop_id } = payload

    const req = {
      page,
      count: 10,
      range_from,
      range_to,
      not_show: '1',
    }
    if (shop_id) {
      req.shop_id = shop_id
    }
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/handbook/tables`,
        {
          params: req,
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      const res = response ? response.data : []
      commit('SET_TABLES', res)
    } catch (e) {
      console.log(e)
    }
  },
  async DOWNLOAD_QR_CODE({ commit }, payload) {
    console.log('payload', payload)
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/handbook/table-picture-base64/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response', response)
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_TABLES(state) {
    return state.tables
  },
  GET_RESTAURANTS(state) {
    return state.restaurants
  },
  GET_RESTAURANTS_PAGES(state) {
    return state.restaurants_pages
  },
  GET_SELECT_REST(state) {
    return state.selectRest
  },
  GET_QR(state) {
    return state.qr
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
