import axios from 'axios'

const state = () => ({
  rkeeperData: {},
  error: false,
  platformsData: null,
  result: null,
  acquiringSupport: null,
  acquiringSupportAlert: null,
})

const mutations = {
  SET_RKEEPER_DATA(state, data) {
    state.rkeeperData = data
  },
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_PLATFORMS_DATA(state, data) {
    state.platformsData = data
  },
  SET_RESULT_DATA(state, data) {
    state.result = data
  },
  SET_ACQUIRIG_SUPPORT(state, data) {
    state.acquiringSupport = data
  },
  SET_ACQUIRIG_ALERT(state, msg) {
    state.acquiringSupportAlert = msg
  },
}

const actions = {
  async SETUP_ACQUIRIG_SUPPORT({ commit }, payload) {
    commit('SET_ACQUIRIG_ALERT', null)
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/acquiring`,
        {
          acquiring_id: payload.id_shop,
          currency_type: payload.currency_type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('SETUP_ACQUIRIG_SUPPORT', response.data)
    } catch (e) {
      console.log('SETUP_ACQUIRIG_SUPPORT', e)
    }
  },
  async FETCH_ACQUIRIG_SUPPORT({ commit }, payload) {
    commit('SET_ACQUIRIG_ALERT', null)
    try {
      const currentData = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/acquiring-settings?shop_id=${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const generalData = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/acquiring-settings/info?shop_id=${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const taxationsModified =
        generalData.data.data && generalData.data.data.taxation_systems
          ? generalData.data.data?.taxation_systems
          : []

      await commit('SET_ACQUIRIG_SUPPORT', {
        acquirings: generalData.data?.data?.acquirings.map((e) => ({
          ...e,
          name: e.code,
        })),
        currencyTypes: generalData.data.data?.currencies,
        data: currentData.data.data,
        taxations: taxationsModified,
        tax_all: generalData.data?.data?.taxs,
      })
      if (currentData.data.errorText) {
        commit('SET_ACQUIRIG_ALERT', currentData.data.errorText)
        return false
      } else {
        return true
      }
    } catch (e) {
      commit('SET_ACQUIRIG_ALERT', 'Заведение не подключено')
      console.log(e)
      return false
    }
  },
  async FETCH_DATA_RKEEPER({ commit }, payload) {
    commit('SET_ERROR', false)
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response FETCH_DATA_RKEEPER', response)
      if (!response) {
        commit('SET_ERROR', true)
      }
      if (!response.data.succes) {
        commit('SET_RKEEPER_DATA', response.data.result)
      }
    } catch (e) {
      console.log(e)
    }
  },
  async SAVE_DATA_RKEEPER({ commit }, payload) {
    const data = {
      ...payload.data,
    }
    commit('SET_ERROR', false)
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      commit('SET_ERROR', true)
      console.log(e)
    }
  },
  async FETCH_DATA_IIKO({ commit }, payload) {
    console.log('payload', payload)
    commit('SET_ERROR', false)
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/iiko/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('iiko', response)
      if (!response) {
        commit('SET_ERROR', true)
      }
      if (!response.data.succes) {
        commit('SET_RKEEPER_DATA', response.data.result)
      }
    } catch (e) {
      console.log(e)
    }
  },
  async SAVE_DATA_IIKO({ commit }, payload) {
    const data = {
      ...payload.data,
    }
    console.log('data', data)
    commit('SET_ERROR', false)
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/iiko`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('SAVE IIKO', response)
    } catch (e) {
      commit('SET_ERROR', true)
      console.log(e)
    }
  },
  // Получение информации по платформе
  async FETCH_PLATFORM({ commit }, payload) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/platform?id=${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      const data = {
        errorText: null,
        data: null,
        platforms: response.data.platforms,
      }
      if (response.data.success) {
        data.data = response.data.data
      } else {
        data.errorText = response.data.errorText
      }

      commit('SET_PLATFORMS_DATA', data)
    } catch (e) {
      console.log(e)
    }
  },
  // Сохранить платформу
  async SAVE_PLATFORM({ commit, state }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/platform?id=${payload.id}`,
        { platform_id: payload.platform_id },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      const data = {
        ...state.platformsData,
        errorText: null,
      }
      commit('SET_PLATFORMS_DATA', data)
      return response.data
    } catch (e) {
      console.log(e)
    }
  },

  async SHOP_ACTIVE({ commit, state }, payload) {
    // console.log('payload', payload)
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/shop/active/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_RESULT_DATA', response.data.result)
      console.log('SHOP_ACTIVE', response.data)
    } catch (e) {
      console.log(e)
    }
  },
  async SHOP_ACTIVE_CONSERVATION({ commit }, payload) {
    console.log('payloadpayload', payload)
    const data = {
      ...payload.data,
    }

    // console.log('data', data)
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/shop/active/${data.id}`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      // console.log('SHOP_ACTIVE_CONSERVATION', response.data)
    } catch (e) {
      console.log('e', e)
    }
  },
}

const getters = {
  RKEEPER_DATA(state) {
    return state.rkeeperData
  },
  ERROR(state) {
    return state.error
  },
  GET_PLATFORMS_DATA(state) {
    return state.platformsData
  },
  GET_ACTIVITY_STATUS_DATA(state) {
    return state.result
  },
  GET_ACQUIRIG_SUPPORT(state) {
    return state.acquiringSupport
  },
  GET_ACQUIRIG_ALERT(state) {
    return state.acquiringSupportAlert
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
