import axios from 'axios'
const state = {
  services: null,
}

const mutations = {
  SET_SERVICES(state, service) {
    state.services = service
  },
}

const actions = {
  async GET_INTEGRATION_SERVIVES({ commit }, payload) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/services/connection-platform`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (response.data.success) {
        commit('SET_SERVICES', response.data.result)
      }
      console.log('response GET_INTEGRATION_SERVIVES', response)
    } catch (e) {
      console.log(e)
    }
  },
  async INTEGRATION({ commit }, payload) {
    console.log('payload', payload)
    const require = {
      platform: payload.service,
    }
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/services/connection-platform`,
        require,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_SERVICES', { name: payload.service })
      console.log('response INTEGRATION', response)
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  SERVICES(state) {
    return state.services
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
