<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico svgFill"
  >
    <path
      d="M9 0C8.59032 0 8.25704 0.333281 8.25704 0.742957C8.25704 1.15263 8.59032 1.48591 9 1.48591C13.1433 1.48591 16.5141 4.8567 16.5141 8.99996C16.5141 13.1432 13.1433 16.5141 9 16.5141C4.85673 16.5141 1.48595 13.1433 1.48595 9C1.48595 8.59032 1.15267 8.25704 0.742992 8.25704C0.333316 8.25704 0 8.59032 0 9C0 13.9712 4.02251 18 9 18C13.9712 18 18 13.9775 18 9C18 4.02877 13.9775 0 9 0Z"
    />
    <path
      d="M9.00004 15.4578C12.5618 15.4578 15.4594 12.5601 15.4594 8.99843C15.4594 5.43671 12.5618 2.53906 9.00004 2.53906C8.59036 2.53906 8.25708 2.87234 8.25708 3.28202C8.25708 3.6917 8.59036 4.02498 9.00004 4.02498C11.7424 4.02498 13.9735 6.25606 13.9735 8.99843C13.9735 11.7408 11.7424 13.9719 9.00004 13.9719C8.59036 13.9719 8.25708 14.3052 8.25708 14.7148C8.25708 15.1245 8.59036 15.4578 9.00004 15.4578Z"
    />
    <path
      d="M9.00007 6.56798C10.3415 6.56798 11.4329 7.65934 11.4329 9.00079C11.4329 9.40906 11.7655 9.74375 12.1758 9.74375C12.5855 9.74375 12.9188 9.41047 12.9188 9.00079C12.9188 6.83998 11.1608 5.08203 9.00004 5.08203C8.59036 5.08203 8.25708 5.41531 8.25708 5.82499C8.25712 6.23396 8.5905 6.56798 9.00007 6.56798Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconAnalytics',
}
</script>
