<template>
  <div class="vPreloader">
    <img
      src="~@/assets/images/preloader.gif"
      alt="Загрузка..."
      class="vPreloader__img"
    >
    <div class="vPreloader__text">
      <template v-if="PRELOADER_TEXT">
        {{ PRELOADER_TEXT }}
      </template>
      <template v-else>
        Еще немного...
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VPreloader',
  computed: {
    ...mapGetters('preloader', ['PRELOADER_TEXT']),
  },
}
</script>
<style lang="scss">
.vPreloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f8fa;
  flex-direction: column;
  &__img {
    width: 110px;
    height: 110px;
    margin-bottom: 10px;
  }
  &__text {
    color: #80869a;
    font-size: 24px;
    font-family: 'Commissioner';
  }
}
</style>
