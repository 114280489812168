import axios from 'axios'

const state = {
  orders: [],
  errors: [],
  updated: '',
  checkTransactionAfterRaiting: true,
}

const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  SET_UPDATED(state, updated) {
    state.updated = updated
  },
  SET_CHECK_TRANSACTION(state, status) {
    state.checkTransactionAfterRaiting = status
  },
}
const actions = {
  CHANGE_STATUS_CHECK_TRANSACTION({ commit }, status) {
    commit('SET_CHECK_TRANSACTION', status)
  },
  UPDATE_ORDERS({ commit }, orders) {
    commit('SET_ORDERS', orders)
  },

  DISABLED_ORDER({ state, commit }, payload) {
    const { positions_id, check_status, guest } = payload
    const changedOrders = state.orders.map((order) =>
      order.id === Number(positions_id)
        ? { ...order, selected_guest_id: check_status ? guest : null }
        : order
    )
    commit('SET_ORDERS', changedOrders)
  },
  UPDATE_POSITION({ state, commit }, payloadArray) {
    const changedOrders = state.orders.map((item) => {
      return payloadArray.find((product) => product.id === item.id) || item
    })
    commit('SET_ORDERS', changedOrders)
  },
  UPDATE_ERRORS({ state, commit }, payload) {
    commit('SET_ERRORS', [...state.errors, payload])
  },
  async CHECK_TRANSACTION({}, id) {
    const res = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/payment/transaction-status/${id}`
    )

    if (res?.errors?.code === 404) return false
    return res?.data
  },
}

const getters = {
  GET_ORDERS(state) {
    return state.orders
  },
  GET_ERRORS(state) {
    return state.errors
  },
  GET_UPDATE(state) {
    return state.updated
  },
  GET_CHECK_TRANSACTION(state) {
    return state.checkTransactionAfterRaiting
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
