<template>
  <footer
    id="footer"
    class="vLandingFooter"
  >
    <div class="vLandingFooter__container">
      <div class="vLandingFooter__top">
        <div class="vLandingFooter__contacts">
          <a
            v-scroll-to="'#header'"
            href="#"
          ><img
            src="@/assets/images/landing_logo.svg"
            alt=""
            class="vLandingFooter__logo"
          ></a>
          <div class="vLandingFooter__top-contacts">
            <a
              href="tel:href=tel:88005006768"
              class="vLandingFooter__phone"
            >8(800) 500-67-68</a>
            <a
              href="mailto:info@tapper.cloud"
              class="vLandingFooter__email"
            >info@tapper.cloud</a>
          </div>
          <p class="vLandingFooter__address">
            123317, г.&nbsp;Москва, Пресненская набережная д.&nbsp;12, башня
            “Федерация Восток”, 37 этаж
          </p>
          <ul class="vLandingFooter__social-list">
            <li class="vLandingFooter__social-item">
              <a
                href="https://t.me/tapper_cloud"
                target="_blank"
                class="vLandingFooter__social-link"
              ><svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M32 16C32 24.8365 24.8365 32 16 32C7.16347 32 0 24.8365 0 16C0 7.16347 7.16347 0 16 0C24.8365 0 32 7.16347 32 16ZM10.7143 14.5343C9.11153 15.2341 7.46473 15.9532 5.95883 16.7827C5.17251 17.3584 6.21759 17.7657 7.19807 18.1477C7.35393 18.2084 7.50813 18.2685 7.65313 18.3285C7.7738 18.3656 7.89647 18.4047 8.0208 18.4443C9.11127 18.7917 10.3271 19.1791 11.3858 18.5963C13.1249 17.5973 14.766 16.4424 16.4059 15.2883C16.9432 14.9102 17.4803 14.5322 18.0207 14.1598C18.0461 14.1436 18.0746 14.1251 18.1059 14.1049C18.5662 13.8064 19.6016 13.1353 19.2186 14.0601C18.313 15.0505 17.3429 15.9271 16.3677 16.8087C15.7103 17.4027 15.0506 17.999 14.4066 18.6337C13.8457 19.0894 13.2633 20.0059 13.8913 20.6441C15.3379 21.6567 16.8071 22.6449 18.2755 23.6325C18.7533 23.9538 19.2311 24.2751 19.7079 24.5973C20.516 25.2425 21.779 24.7205 21.9567 23.7123C22.0357 23.2485 22.115 22.7848 22.1943 22.321C22.6327 19.7579 23.0713 17.1938 23.4587 14.6223C23.5113 14.2189 23.571 13.8156 23.6307 13.4121C23.7755 12.434 23.9204 11.4547 23.9657 10.4714C23.849 9.49007 22.6591 9.70587 21.997 9.92653C18.5935 11.2216 15.2241 12.6127 11.8679 14.0283C11.4877 14.1967 11.1023 14.3649 10.7143 14.5343Z"
                  fill="#FBFBFC"
                /></svg></a>
            </li>
            <li class="vLandingFooter__social-item">
              <a
                href="https://vk.com/tapper.cloud"
                target="_blank"
                class="vLandingFooter__social-link"
              ><svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 16C0 7.16347 7.16347 0 16 0C24.8365 0 32 7.16347 32 16C32 24.8365 24.8365 32 16 32C7.16347 32 0 24.8365 0 16ZM16.837 21.2507C16.837 21.2507 17.145 21.2171 17.3027 21.0508C17.4471 20.8984 17.4421 20.6108 17.4421 20.6108C17.4421 20.6108 17.4229 19.2679 18.0582 19.0695C18.6843 18.8745 19.4881 20.3683 20.3412 20.9427C20.9856 21.3769 21.4747 21.2819 21.4747 21.2819L23.7544 21.2507C23.7544 21.2507 24.9464 21.1786 24.3813 20.2585C24.3345 20.1831 24.0515 19.5776 22.6868 18.3337C21.2569 17.0318 21.4489 17.2423 23.1701 14.9899C24.2185 13.6183 24.6375 12.7809 24.5065 12.4229C24.3821 12.0803 23.6108 12.1713 23.6108 12.1713L21.0449 12.1869C21.0449 12.1869 20.8545 12.1615 20.7135 12.2442C20.5757 12.3253 20.4864 12.5146 20.4864 12.5146C20.4864 12.5146 20.0807 13.5757 19.539 14.4786C18.3963 16.3829 17.9397 16.4836 17.7527 16.3657C17.3178 16.0895 17.4263 15.2579 17.4263 14.6671C17.4263 12.8211 17.7118 12.0517 16.8712 11.8525C16.5924 11.7862 16.3871 11.7427 15.6733 11.7354C14.7577 11.7264 13.983 11.7387 13.5439 11.9493C13.2518 12.0893 13.0264 12.4023 13.1641 12.4204C13.3336 12.4425 13.7176 12.522 13.9213 12.794C14.1842 13.1455 14.175 13.9337 14.175 13.9337C14.175 13.9337 14.3261 16.1067 13.8219 16.3763C13.4763 16.5615 13.0022 16.1837 11.983 14.4557C11.4613 13.5708 11.0673 12.5925 11.0673 12.5925C11.0673 12.5925 10.9913 12.4097 10.8553 12.3114C10.6909 12.1926 10.4613 12.1557 10.4613 12.1557L8.02307 12.1713C8.02307 12.1713 7.6566 12.1811 7.5222 12.3376C7.40287 12.4761 7.513 12.7637 7.513 12.7637C7.513 12.7637 9.42207 17.1481 11.584 19.358C13.5665 21.3835 15.8169 21.2507 15.8169 21.2507H16.837Z"
                  fill="#FBFBFC"
                /></svg></a>
            </li>
            <li class="vLandingFooter__social-item">
              <a
                href="https://www.linkedin.com/company/tapper-cloud"
                target="_blank"
                class="vLandingFooter__social-link"
              ><svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 16C0 7.16347 7.16347 0 16 0C24.8365 0 32 7.16347 32 16C32 24.8365 24.8365 32 16 32C7.16347 32 0 24.8365 0 16ZM11.307 13.2519H7.68107V24.1464H11.307V13.2519ZM11.5459 9.8818C11.5223 8.8136 10.7585 8 9.518 8C8.2776 8 7.46667 8.8136 7.46667 9.8818C7.46667 10.9279 8.25367 11.7649 9.47093 11.7649H9.49413C10.7585 11.7649 11.5459 10.9279 11.5459 9.8818ZM24.3836 17.8998C24.3836 14.5535 22.5948 12.9961 20.2087 12.9961C18.2836 12.9961 17.4217 14.0535 16.9405 14.7953V13.2522H13.3141C13.3619 14.2745 13.3141 24.1467 13.3141 24.1467H16.9405V18.0623C16.9405 17.7367 16.964 17.4119 17.0599 17.1787C17.3219 16.5283 17.9186 15.8548 18.9203 15.8548C20.2329 15.8548 20.7577 16.8538 20.7577 18.3178V24.1464H24.3835L24.3836 17.8998Z"
                  fill="#FBFBFC"
                /></svg></a>
            </li>
          </ul>
        </div>
        <div class="vLandingFooter__about">
          <h3 class="vLandingFooter__subtitle">
            О нас
          </h3>
          <nav class="vLandingFooter__nav">
            <ul class="vLandingFooter__list">
              <li class="vLandingFooter__nav-item">
                <a
                  v-scroll-to="'#howwork'"
                  href="#"
                  class="vLandingFooter__nav-link"
                >Как это работает?</a>
              </li>
              <li class="vLandingFooter__nav-item">
                <a
                  v-scroll-to="'#integrations'"
                  href="#"
                  class="vLandingFooter__nav-link"
                >Интеграции</a>
              </li>
              <li class="vLandingFooter__nav-item">
                <a
                  v-scroll-to="'#questions'"
                  href="#"
                  class="vLandingFooter__nav-link"
                >Вопросы и ответы</a>
              </li>
              <li class="vLandingFooter__nav-item">
                <a
                  v-scroll-to="'#posibilities'"
                  href="#"
                  class="vLandingFooter__nav-link"
                >Возможности</a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="vLandingFooter__requsites">
          <h3 class="vLandingFooter__subtitle">
            Реквизиты
          </h3>
          <ul class="vLandingFooter__requsites-list">
            <li class="vLandingFooter__requsites-item">
              <p>ООО «ФАУНДАРИУМ»</p>
              <p>
                Юридический адрес: 125212, г.&nbsp;Москва, Головинское шоссе,
                д.&nbsp;5, корп./ст.&nbsp;1, кв./оф.&nbsp;2136
              </p>
            </li>
            <li class="vLandingFooter__requsites-item">
              <p>ИНН: 7743365653</p>
              <p>КПП: 774301001</p>
              <p>ОГРН: 1217700404206</p>
            </li>
            <li class="vLandingFooter__requsites-item">
              <p>Банк: ОАО «АЛЬФА-БАНК»</p>
              <p>БИК: 044525593</p>
              <p>Р/c: 40702810002740004945</p>
              <p>К/с: 30101810200000000593</p>
            </li>
          </ul>
        </div>
        <router-link
          v-if="GET_AUTHENTIFICATED !== null"
          to="/profile"
          class="vLandingFooter__login-link"
        >
          Профиль
        </router-link>
        <router-link
          v-else
          to="/users"
          class="vLandingFooter__login-link"
        >
          Войти
        </router-link>
      </div>
      <div class="vLandingFooter__bottom">
        <p class="vLandingFooter__copyright">
          Copyright © 2021 Tapper.cloud
        </p>
        <div class="vLandingFooter__bottom-links">
          <a
            href="#conf"
            class="vLandingFooter__bottom-link"
            @click="togglePoliticModal"
          >Условия использования и Политика конфиденциальности</a>
          <a
            href="#pay"
            class="vLandingFooter__bottom-link"
            @click="togglePayModal"
          >Политика безопасности платежей</a>
        </div>
        <a
          href="https://foundarium.com/"
          target="_blank"
          class="vLandingFooter__powered"
        ><img
          src="~@/assets/images/footer_powered.png"
          alt=""
        ></a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'VLandingFooter',
  emits: ['togglePoliticModal', 'togglePayModal'],
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  methods: {
    ...mapActions('modalPolitic', ['CHANGE_MODAL_POLITIC']),
    togglePoliticModal() {
      this.CHANGE_MODAL_POLITIC()
    },
    togglePayModal() {
      this.$emit('togglePayModal')
    },
  },
}
</script>

<style lang="scss">
.vLandingFooter {
  padding: 44px 0 25px;
  background: linear-gradient(93.92deg, #14141b 1.17%, #0c0c10 94.76%);
  @media (max-width: 769px) {
    padding: 60px 0 24px;
  }
  &__container {
    width: 100%;
    max-width: 1200px;
    padding: 0 36px;
    margin: 0 auto;
    @media (max-width: 769px) {
      padding: 0 20px;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #e4e4eb;
    border-bottom: 1px solid #2c3038;
    @media (max-width: 769px) {
      flex-direction: column;
      padding-bottom: 24px;
    }
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    a {
      align-self: flex-start;
    }
  }
  &__logo {
    width: 104px;
    height: 40px;
    margin-bottom: 27px;
    @media (max-width: 991px) {
      width: 90px;
      height: 32px;
      margin-right: 10px;
    }
    @media (max-width: 769px) {
      margin-bottom: 24px;
      width: 104px;
      height: 40px;
    }
  }
  &__top-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 19px;
    @media (max-width: 1023px) {
      margin-right: 30px;
    }
    @media (max-width: 769px) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
  &__phone {
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.55px;
    color: #fbfbfc;
    text-decoration: none;
    margin-bottom: 5px;
    @media (max-width: 991px) {
      font-size: 18px;
    }
    @media (max-width: 769px) {
      margin-bottom: 8px;
      font-size: 22px;
      align-self: flex-start;
    }
  }
  &__email {
    margin-bottom: 13px;
    color: #e4e4eb;
    text-decoration: none;
    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 769px) {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      align-self: flex-start;
    }
  }
  &__address {
    max-width: 220px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 769px) {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__social-list {
    display: flex;
    list-style-type: none;
    @media (max-width: 769px) {
      margin-bottom: 30px;
    }
  }
  &__social-item {
    &:not(:last-child) {
      margin-right: 16px;
      @media (max-width: 991px) {
        margin-right: 10px;
      }
      @media (max-width: 769px) {
        margin-right: 16px;
      }
    }
  }
  &__social-link {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    align-self: flex-start;
  }
  &__about {
    @media (max-width: 769px) {
      order: 2;
    }
  }
  &__subtitle {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fbfbfc;
  }
  &__nav {
    @media (max-width: 769px) {
      padding-bottom: 24px;
      margin-bottom: 24px;
    }
  }
  &__list {
    list-style-type: none;
  }
  &__nav-item {
    margin-bottom: 12px;
    @media (max-width: 1023px) {
      margin-right: 30px;
    }
    @media (max-width: 767px) {
      margin-bottom: 12px;
    }
  }
  &__nav-link {
    color: #e4e4eb;
    text-decoration: none;
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 769px) {
      order: 2;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
    }
  }
  &__requsites {
    @media (max-width: 769px) {
      order: 3;
    }
  }
  &__requsites-list {
    max-width: 264px;
    list-style-type: none;
  }
  &__requsites-item {
    margin-bottom: 17px;
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 769px) {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    @media (max-width: 769px) {
      flex-direction: column;
    }
  }
  &__copyright {
    font-family: 'PTRootUI';
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #5a5a68;
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 769px) {
      order: 1;
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__powered {
    @media (max-width: 769px) {
      order: 2;
    }
  }
  &__bottom-link {
    margin-bottom: 12px;
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #e4e4eb;
    text-decoration: none;
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 769px) {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__bottom-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 769px) {
      order: 0;
      margin-bottom: 24px;
    }
  }
  &__login-link {
    padding: 16px 43px;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fbfbfc;
    border: 2px solid #9c1313;
    border-radius: 8px;
    text-decoration: none;
    align-self: start;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    &:hover {
      background-color: #e01b1c;
      border: 2px solid #9c1313;
    }
    @media (max-width: 991px) {
      font-size: 14px;
      padding: 16px 23px;
      width: auto;
    }
    @media (max-width: 769px) {
      order: 1;
      margin-bottom: 30px;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
