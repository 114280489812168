import axios from 'axios'

const state = {
  logsData: {},
  idLicense: null,
}

const mutations = {
  SET_LOGS(state, payload) {
    state.logsData = payload
  },
  SET_LICNSE(state, data) {
    state.idLicense = data
  },
}

const actions = {
  async FETCH_LICENSE_TYPE(_, payload) {
    console.log('actions FETCH_LICENSE_TYPE', payload)
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}/license/type`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return response.data.result.license_type
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_END_DATA_LICENSE(_, payload) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}/license/date-expiration`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return response.data['date-expiration']
    } catch (e) {
      console.log(e)
    }
  },
  async SAVE_LICENSE(_, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}/license/type`,
        {
          license_type: payload.type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_LICENSE({ commit }, payload) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response FETCH_LICENSE', response)

      let data = {
        date_expiration_license: '',
        id_license: '',
      }

      if (response.data.success) {
        data = {
          date_expiration_license: response.data.result.date_expiration_license,
          id_license: response.data.result.id_license,
        }
      }

      commit('SET_LICNSE', data)
      return true
    } catch (e) {
      console.log(e)
      const data = {
        date_expiration_license: '',
        id_license: '',
      }

      commit('SET_LICNSE', data)
      return false
    }
  },
  async UPDATE_ID_LICENSE({ commit }, payload) {
    console.log('payload', payload)
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/rkeeperupdatelicense`,
        { id: payload.id },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const data = {
        date_expiration_license: response.data.date_expiration_license,
        id_license: response.data.id_license,
      }

      commit('SET_LICNSE', data)
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_LOGS({ commit }, data) {
    console.log('data', data)
    await axios
      .get(
        `${process.env.VUE_APP_BASE_URL}/logs/?page=${data.page}&date=${data.date}&shops_id=${data.shops_id}`,
        {
          headers: {
            Authorization: 'Bearer ' + data.token,
          },
        }
      )
      .then((res) => {
        console.log('FETCH LOGS --> ', res)

        if (res.data.data.length) {
          const fields = Object.keys(res.data.data[0])

          console.log('Все норм')

          const newData = {
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            logs: res.data.data,
            entries: [],
            fields,
          }
          newData.logs.forEach((i) => {
            newData.entries.push(Object.entries(i))
          })
          commit('SET_LOGS', newData)
        } else {
          console.log('НЕ норм!')
          commit('SET_LOGS', {})
        }
      })
      .catch((e) => {
        console.log('err', e)
      })
  },
  async CLEAR_CACHE_TABLES({}, payload) {
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/update-cache-table`,
        { id: payload.id },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return res.data
    } catch (e) {
      console.log(e)
    }
  },
  async CLEAR_CACHE_ALL({}, payload) {
    try {
      await axios.get(`${process.env.VUE_APP_BASE_URL}/support/cache-clear`, {
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      })
      return true
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  LOGS_DATA(state) {
    return state.logsData
  },
  LICENSE_DATA(state) {
    return state.idLicense
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
