<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M5.55796 9.76295L5.27096 8.69596C4.98196 7.62696 5.61696 6.52795 6.68696 6.24295L16.73 3.56695C17.79 3.28495 18.89 3.91996 19.176 4.97896L20.93 11.488C21.218 12.557 20.582 13.656 19.514 13.941L17.4 14.504"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 14.077H17.4M5 20.737H15.4C16.505 20.737 17.4 19.842 17.4 18.737V12C17.4 10.895 16.505 10 15.4 10H5C3.895 10 3 10.895 3 12V18.737C3 19.842 3.896 20.737 5 20.737V20.737Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconOrderHistory',
}
</script>

<style lang="scss"></style>
