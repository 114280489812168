import axios from 'axios'

const state = () => ({
  tips_type: '',
})

const mutations = {
  SET_TIPS_TYPE(state, status) {
    state.tips_type = status
  },
}

const actions = {
  async CHANGE_TIPS_TYPE(_, payload) {
    const { token, id, type } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/tips/`,
        {
          shop_id: id,
          tips_type: type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_ENABLED_TIPS(state) {
    return state.enabledTips
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
