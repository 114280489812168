import axios from 'axios'

const state = () => ({
  customization_list: [],
  customization: null,
})

const mutations = {
  SET_CUSTOMIZATION_LIST(state, list) {
    state.customization_list = list
  },
  SET_CUSTOMIZATION(state, type) {
    state.customization = type
  },
}

const actions = {
  async FETCH_CUSTOMIZATION_LIST({ commit }, token) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/customization/`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_CUSTOMIZATION_LIST', res.data.result)
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_CUSTOMIZATION_BY_CODE({ commit }, payload) {
    const { code } = payload
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/shop/customization?code=${code}`
      )
      commit(
        'SET_CUSTOMIZATION',
        res.data.result ? res.data.result.code : 'TAPPER'
      )
    } catch (e) {
      console.log(e)
    }
  },
  async UPDATE_CUSTOMIZATION(_, payload) {
    const { token, shopId, customizationId } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/shop/set-customization`,
        {
          shop_id: shopId,
          customization_id: customizationId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_CUSTOMIZATION_LIST(state) {
    return state.customization_list
  },
  GET_CUSTOMIZATION(state) {
    return state.customization
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
