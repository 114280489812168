const state = {
  authentication_status: false,
}

const mutations = {
  SET_STATUS(state, payload) {
    state.authentication_status = payload
  },
}
const actions = {
  CHAANGE_STATUS({ commit }, payload) {
    commit('SET_STATUS', payload)
  },
}

const getters = {
  GET_AUTHENTICATION_STATUS(state) {
    return state.authentication_status
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
