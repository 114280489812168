import axios from 'axios'
import logs from './logs'

const state = () => ({
  info_wifi: [],
})

const mutations = {
  SET_INFO_WIFI(state, list) {
    state.info_wifi = list
  },
}

const actions = {
  // Получение данных Wi-Fi
  async FETCH_INFO_WIFI({ commit }, token) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/wifi`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_INFO_WIFI', res.data.result)
      return res.data.result
    } catch (e) {
      console.log(e)
    }
  },

  // Добавление/Обновление wifi для ресторана
  async UPDATING_WIFI_INFORMATION({ commit }, payload) {
    try {
      const query = {
        network: payload.network,
        password: payload.password,
        active: payload.active,
      }
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/wifi`,
        query,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_INFO_WIFI(state) {
    return state.info_wifi
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
