import axios from 'axios'

const state = () => ({
  details: null,
  acquiring: null,
  alert: null,
  iikoId: null,
})

const mutations = {
  SET_DETAILS(state, payload) {
    state.details = payload
  },
  SET_ACQUIRIG(state, payload) {
    state.acquiring = payload
  },
  SET_ALERT(state, msg) {
    state.alert = msg
  },
  SET_IIKO_ID(state, id) {
    state.iikoId = id
  },
}

const actions = {
  async SAVE_ACQUIRIG({ commit }, payload) {
    commit('SET_ALERT', null)
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/acquiring-settings/`,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response SAVE_ACQUIRIG', response)
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_ACQUIRIG({ commit }, payload) {
    commit('SET_ALERT', null)
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/acquiring-settings/?shop_id=${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response SET_ACQUIRIG', response)
      if (response.data.message) {
        commit('SET_ALERT', response.data.message)
      }
      commit('SET_ACQUIRIG', response.data.data)
    } catch (e) {
      console.log(e)
      commit('SET_ALERT', 'Все плохо :/ ')
    }
  },
  async GET_ID_IIKO(_, payload) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/iiko/get-id/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return res.data.success
    } catch (e) {
      console.log(e)
    }
  },
  async GETTING_DETAILS({ commit }, payload) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/shops/requisites`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_DETAILS', response.data.result)
    } catch (e) {
      console.log(e)
    }
  },
  async SAVING_DETAILS_INSTITUTION({ commit }, payload) {
    console.log('payload-qweqwe', payload)
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/shop/${payload.id}/requisites`,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response SAVING_DETAILS_INSTITUTION', response)
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_DETAILS(state) {
    return state.details
  },
  GET_ACQUIRIG(state) {
    return state.acquiring
  },
  GET_ALERT(state) {
    return state.alert
  },
  GET_IIKO_ID(state) {
    return state.iikoId
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
