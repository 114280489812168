import { createStore } from 'vuex'
import acquiring from './modules/acquiring'
import alertModalText from './modules/alertModalText'
import authentication from './modules/authentication'
import cloud from './modules/cloud'
import connection from './modules/connection'
import customization from './modules/customization'
import externalReviews from './modules/externalReviews'
import feedback from './modules/feedback'
import informationWiFi from './modules/informationWiFi'
import integration from './modules/integration'
import layout from './modules/layout'
import loader from './modules/loader'
import logs from './modules/logs'
import loyalty from './modules/loyalty'
import menu from './modules/menu'
import menuAdmin from './modules/menuAdmin'
import meta from './modules/meta'
import modalPolitic from './modules/modalPolitic'
import orders from './modules/orders'
import plug from './modules/plug'
import preloader from './modules/preloader'
import requisites from './modules/requisites'
import restaurant from './modules/restaurant'
import send from './modules/send'
import shops from './modules/shops'
import statistic from './modules/statistic'
import support from './modules/support'
import telegram from './modules/telegram'
import tips from './modules/tips'
import transactions from './modules/transactions'
import user from './modules/user'
import virtualCard from './modules/virtualCard'
import waiter from './modules/waiter'
import legalDocuments from './modules/legalDocuments'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    layout,
    user,
    shops,
    feedback,
    preloader,
    menu,
    integration,
    telegram,
    send,
    modalPolitic,
    waiter,
    restaurant,
    authentication,
    logs,
    alertModalText,
    requisites,
    support,
    transactions,
    virtualCard,
    orders,
    meta,
    acquiring,
    loader,
    connection,
    menuAdmin,
    statistic,
    cloud,
    tips,
    plug,
    customization,
    informationWiFi,
    loyalty,
    externalReviews,
    loyalty,
    legalDocuments,
  },
})
