const state = {
  menuProfile: null,
}

const mutations = {
  SET_MENU_PROFILE(state, payload) {
    state.menuProfile = payload
  },
}
const actions = {
  CHAANGE_ACTIVE_PROFILE_MENU({ commit }, payload) {
    commit('SET_MENU_PROFILE', payload)
  },
}

const getters = {
  GET_MENU_PROFILE(state) {
    return state.menuProfile
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
