<template>
  <Field
    v-slot="{ field, errorMessage }"
    :name="id"
    :rules="rule"
    :model-value="modelValue"
  >
    <div
      class="vLandingInput"
      :class="[
        { err: errorMessage || error.length },
        { emptyFieldError: required && !modelValue },
        {
          emptyFieldError:
            emptyFieldError && modelValue && modelValue.length < 1,
        },
      ]"
      :style="{ marginBottom: marginBottom + 'px' }"
    >
      <div
        class="vLandingInput__wrapper"
        :class="{ disabled: disabled }"
      >
        <input
          v-if="id === 'phone'"
          v-bind="field"
          :id="id"
          v-maska="'+7(###) ###-##-##'"
          :value="modelValue"
          :name="id"
          :type="type"
          class="vLandingInput__field"
          autocomplete="off"
          :class="{ active: modelValue }"
          :required="required"
          :disabled="disabled"
          @input="onInput($event.target.value)"
        >
        <input
          v-else
          v-bind="field"
          :id="id"
          v-maska="mask"
          :name="id"
          :type="showPassword ? 'text' : type"
          class="vLandingInput__field"
          autocomplete="off"
          :class="{ active: modelValue || modelValue !== '' }"
          :required="required"
          :disabled="disabled"
          :maxlength="maxLength"
          @paste="onPaste"
          @input="onInput($event.target.value)"
        >
        <div
          class="vLandingInput__label"
          :style="{ fontSize: mobileScreen && 13 + 'px' }"
        >
          {{ placeholder }}
          <span
            v-if="required"
            class="vLandingInput__star"
          >*</span>
        </div>
        <svg
          v-if="type == 'password' && !showPassword"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="vLandingInput__eye"
          @click="toggleShowPassword"
        >
          <path
            d="M12 19C11.158 19 10.315 18.822 9.496 18.505L12 19ZM12 19C15.495 19 18.99 15.967 20.882 12.468C21.039 12.177 21.039 11.824 20.882 11.533C20.384 10.612 19.77 9.73 19.079 8.921M5 19L19 5M9.773 14.227C8.543 12.997 8.543 11.002 9.773 9.772C11.003 8.542 12.998 8.542 14.228 9.772"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.0438 6.956C15.4968 5.759 13.7478 5 11.9998 5C8.50475 5 5.00975 8.033 3.11775 11.533C2.96075 11.824 2.96075 12.177 3.11775 12.468C4.06375 14.217 5.40975 15.849 6.95575 17.045"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="type == 'password' && showPassword"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="vLandingInput__eye"
          @click="toggleShowPassword"
        >
          <path
            d="M3.11824 12.467C2.96124 12.176 2.96124 11.823 3.11824 11.532C5.01024 8.033 8.50524 5 12.0002 5C15.4952 5 18.9902 8.033 20.8822 11.533C21.0392 11.824 21.0392 12.177 20.8822 12.468C18.9902 15.967 15.4952 19 12.0002 19C8.50524 19 5.01024 15.967 3.11824 12.467V12.467Z"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.1213 9.87868C15.2929 11.0502 15.2929 12.9497 14.1213 14.1213C12.9497 15.2929 11.0502 15.2929 9.87868 14.1213C8.70711 12.9497 8.70711 11.0502 9.87868 9.87868C11.0502 8.70711 12.9497 8.70711 14.1213 9.87868Z"
            stroke="#80869A"
            stroke-width="1.429"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div class="vLandingInput__socialIcon">
          <slot />
        </div>
      </div>
      <div class="vLandingInput__err">
        {{ error && parseInt(error.length) > 0 ? error : '' }}
        <br v-if="parseInt(error.length) > 0">
        {{ errorMessage }}
      </div>
    </div>
  </Field>
</template>

<script>
import { Field } from 'vee-validate'
import { defineRule, configure } from 'vee-validate'

configure({
  validateOnInput: true,
})

// Обязательное поле
defineRule('required', (modelValue) => {
  if (modelValue && modelValue.trim()) {
    return true
  }
  return 'Поле не может быть пустым!'
})

// Только русские символы
defineRule('rus', (modelValue) => {
  const regexp = /^[а-яА-ЯёЁ\s]+$/
  const res = regexp.test(modelValue)
  if (res) {
    return true
  }
  return 'Имя может содержать только буквы А-Я'
})

// Телефон
defineRule('phone', (modelValue) => {
  if (modelValue.length === 17) {
    return true
  }
  return 'Некорректный номер телефона'
})

// mail
defineRule('mail', (modelValue) => {
  const regexp =
    /(^[aA-zZ0-9])([^@\{}!#()\s]*)(@)([^@\{}!#()\s]*)(\.)([aA-zZ]{2,9}$)/
  const res = regexp.test(modelValue)
  if (res) {
    return true
  }
  return 'Введите корректный E-mail'
})

// TelegramID
defineRule('telegramId', (modelValue) => {
  const regexp = /^[-]\d+$/
  const res = regexp.test(modelValue)
  if (res) {
    return true
  }
  return 'Введите корректный ID'
})

// Минимальное кол-во
defineRule('min', (modelValue, args) => {
  if (modelValue.length >= args[0]) {
    return true
  }
  return `Введите минимум ${args[0]} символов`
})

// Максимальное кол-во
defineRule('max', (modelValue, args) => {
  if (modelValue && modelValue.length <= args[0]) {
    return true
  }
  return `Введите не более ${args[0]} символов`
})

// Проверка совпадения пароля
defineRule('confirmed', (modelValue, [target]) => {
  if (modelValue === target) {
    return true
  }
  return 'Пароли не совпадают'
})

// Название заведения
defineRule('name_shop', (modelValue) => {
  const regexp = /[!#(){}]/
  const res = regexp.test(modelValue)
  if (!res) {
    return true
  }
  return 'Введите корректное название, без использования символов ! # ( ) { }'
})

export default {
  name: 'VLandingInput',
  components: {
    Field,
  },
  props: {
    blockPaste: {
      type: Boolean,
      require: false,
      default: true,
    },
    placeholder: {
      type: String,
      require: true,
      default: '',
    },
    mask: {
      type: String,
      require: false,
      default: '',
    },
    required: {
      type: Boolean,
      require: false,
      default: false,
    },
    id: {
      type: String,
      require: true,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      require: true,
      default: '',
    },
    value: {
      type: [String, Number],
      require: true,
      default: '',
    },
    type: {
      type: String,
      require: false,
      default: 'text',
    },
    rule: {
      type: String,
      require: false,
      default: '',
    },
    error: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    emptyFieldError: {
      type: Boolean,
      require: false,
      default: false,
    },
    maxLength: {
      type: String,
      require: false,
      default: '',
    },
    marginBottom: {
      type: Number,
      require: false,
      default: 20,
    },
    mobileScreen: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],
  data() {
    return {
      showPassword: false,
    }
  },
  methods: {
    onInput(val) {
      this.$emit('update:modelValue', val)
    },
    onPaste(evt) {
      if (this.id === 'confirmation' || this.id === 'password') {
        if (this.blockPaste) {
          evt.preventDefault()
        }
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>

<style lang="scss">
.vLandingInput {
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
  &.err {
    .vLandingInput__wrapper {
      border: 1px solid #ec4e4e;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #f8e0e0;
        z-index: 1;
      }
    }
  }
  &__wrapper {
    position: relative;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 1;
    }
    &.disabled {
      &::after {
        background: #e6e7eb;
      }
    }
  }
  &__field {
    width: 100%;
    padding: 18px 42px 10px 12px;
    background: transparent;
    border: none;
    font-family: 'PTRootUI';
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    z-index: 3;
    color: #010d35;
    outline: none;
    &:focus + .vLandingInput__label,
    &.active + .vLandingInput__label {
      top: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      z-index: 2;
    }
    &:disabled {
      color: #353841;
    }
  }
  &__label {
    position: absolute;
    top: 50%;
    left: 12px;
    font-weight: 500;
    line-height: 20px;
    font-size: 16px;
    color: #80869a;
    transform: translateY(-50%);
    transition: all 0.3s;
    z-index: 2;
  }
  &__star {
    color: #c01718;
  }
  &__invalid-icon {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  &__eye {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 3;
  }
  &__err {
    padding-left: 12px;
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ec4e4e;
  }

  &__socialIcon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 3;
  }
}

.emptyFieldError {
  .vLandingInput__err {
    display: none;
  }
  &.vLandingInput.err .vLandingInput__wrapper {
    border-color: #dfe4ea;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #fbfbfc;
      z-index: 1;
    }
  }
  .vLandingInput__invalid-icon {
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
