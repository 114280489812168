<template>
  <div class="vLandingPoliticModal">
    <div
      class="vLandingPoliticModal__overlay"
      @click="togglePoliticModal"
    />
    <div class="vLandingPoliticModal__content">
      <h2 class="vLandingPoliticModal__title">
        УСЛОВИЯ ИСПОЛЬЗОВАНИЯ И ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ Tapper
      </h2>
      <div class="vLandingPoliticModal__block">
        <p
          v-if="!oldConditions"
          class="vLandingPoliticModal__text vLandingPoliticModal__text--underline"
        >
          Редакция от “10” ноября 2021 г.
        </p>

        <p
          v-if="oldConditions"
          class="vLandingPoliticModal__text vLandingPoliticModal__text--underline vLandingPoliticModal__text--link"
          @click="toggleOldConditions(false)"
        >
          Редакция от “10” ноября 2021 г.
        </p>

        <p
          v-if="!oldConditions"
          class="vLandingPoliticModal__text vLandingPoliticModal__text--underline vLandingPoliticModal__text--link"
          @click="toggleOldConditions(true)"
        >
          Редакция от “14” октября 2021 г.
        </p>

        <p
          v-if="oldConditions"
          class="vLandingPoliticModal__text vLandingPoliticModal__text--underline"
        >
          Редакция от “14” октября 2021 г.
        </p>

        <p class="vLandingPoliticModal__text">
          Настоящие Условия использования и Политика конфиденциальности являются
          публичным договором - договором публичной оферты в соответствии с
          пунктом 2 статьи 437 Гражданского кодекса Российской Федерации,
          Общества с ограниченной ответственностью «ФАУНДАРИУМ», ОГРН
          1217700404206. Все его условия одинаковы для всех пользователей
          независимо от статуса и организационно-правовой формы.
        </p>
        <p class="vLandingPoliticModal__text">
          Условия использования и Политика конфиденциальности распространяются
          на доступ и использование Веб-сайта
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          и\или мобильного приложения и всех других продуктов и услуг Tapper
          (Далее по тексту — сервисы, сервис, приложение, Веб-сайт,
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>, «мы» ,«нас»,«наш»).
        </p>
        <p class="vLandingPoliticModal__text">
          Загружая, используя, регистрируясь на Веб-сайте и\или в Приложении,
          просматривая, отправляя контент или используя другой доступ и/или
          -используя сервис, Пользователь (Далее по тексту —
          «Вы»,«Вас»,«Вам»,«Ваше») соглашается с этими Условиями и Политикой,
          приведенными ниже. Если вы не желаете соглашаться со всеми этими
          Условиями и Политикой, изложенными в этом документе, прекратите
          использование нашего сервиса.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Минимальный возраст.
        </h3>
        <p class="vLandingPoliticModal__text">
          Услуги на Веб-сайте и\или в Приложении доступны только лицам,
          достигшим совершеннолетия в соответствии с законодательством страны
          резидентом и\или гражданином которой такое лицо является и способным
          заключать юридически обязывающие договоры в соответствии с действующим
          законодательством. Не ограничивая вышесказанное, Услуги в Приложении
          и\или на Веб-сайте не предназначены для использования
          несовершеннолетними лицами. Если вы не соответствуете этим
          требованиям, пожалуйста, не пользуйтесь Услугами и\или Приложением и
          не заходите на Веб-сайт.
        </p>
        <p class="vLandingPoliticModal__text">
          Любое дальнейшее использование Веб-сайта и\или Приложения или любой их
          части означает, что вы прочитали и поняли Условия использования и
          Политику конфиденциальности и соглашаетесь соблюдать все разделы
          Условий использования и Политики конфиденциальности.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Изменения в Условиях использования.
        </h3>
        <p>
          Положения настоящих Условий использования и Политики
          конфиденциальности могут быть в дальнейшем изменены. Каждый раз, когда
          мы вносим изменения в эту Политику конфиденциальности и Условия
          использования, мы опубликуем ее новую редакцию на этой странице и
          укажем дату новой редакции.
        </p>
        <p class="vLandingPoliticModal__text">
          Любые изменения в настоящих Условиях и Политике вступают в силу
          немедленно после их публикации. Продолжение использования вами Сервиса
          после внесения изменений в настоящие Условия и Политику означает ваше
          согласие с этими изменениями. Если вы не желаете соглашаться с любыми
          изменениями настоящих Условий и Политики, немедленно прекратите
          использование Сервиса.
        </p>
        <p class="vLandingPoliticModal__text">
          Эти Условия и Политика также будут регулировать любые обновления или
          новые версии Веб-сайта или Приложения, которые могут заменить и/или
          дополнить исходное Приложение, или Веб-сайт. Если обновление или новая
          версия не сопровождаются Условиями и Политикой, настоящие Условия и
          Политика имеют преимущественную силу.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Термины и определения.
        </h3>
        <p>
          <span class="bold-text">Услуги Tapper</span> - относятся к различным
          услугам, предоставляемым вам Tapper, которые основаны на технологиях
          Интернет и предлагаются через веб-сайт
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>, или мобильное приложение, а также другие формы (включая новые,
          которые станут возможными благодаря будущему технологическому
          развитию). Услуги Tapper включают, помимо прочего, такие компоненты
          Tapper, как облачный сервис для управления заказом в кафе и
          ресторанах.
        </p>
        <p>
          <span class="bold-text">Аккаунт</span> - учетная запись, содержащая
          сведения, которые Пользователь сообщил о себе Администрации в процессе
          прохождения регистрации на Веб-сайте
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          или в процессе работы в аккаунте. Учетная запись Tapper служит
          основанием для того, чтобы Пользователь мог пользоваться своими
          правами в Tapper.
        </p>
        <p>
          <span class="bold-text">Логин</span> - уникальный идентификатор
          Пользователя, служащий для обозначения и идентификации его на
          Веб-сайте<a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
        </p>
        <p>
          <span class="bold-text">Пароль</span> - набор символов, которые
          используются для подтверждения личности Пользователя в момент входа в
          аккаунт (авторизация).
        </p>
        <p>
          <span class="bold-text">Пользователь</span> - любое физическое или
          юридическое лицо, посещающее Веб-сайт
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          или установившее Приложение Tapper.
        </p>
        <p>
          <span class="bold-text">Посетитель</span> - физическое лицо,
          выразившее добровольное желание оформить заказ в соответствующем
          заведении Продавца.
        </p>
        <p>
          <span class="bold-text">Продавец</span> - юридическое лицо или
          индивидуальный предприниматель, зарегистрированный в сервисе Tapper
          осуществляющее(ий) услуги по производству и/или реализации
          Товаров-услуг, в принадлежащем ему заведении.
        </p>
        <p>
          <span class="bold-text">Заведение</span> - кафе, ресторан, пункт
          общественного питания.
        </p>
        <p>
          <span class="bold-text">Администратор</span> - Общество с ограниченной
          ответственностью «ФАУНДАРИУМ»,
          <span
            v-if="!oldConditions"
          >125212, г. Москва, Головинское шоссе, д. 5, корп./ст. 1, кв./оф.
            2136</span>
          <span
            v-if="oldConditions"
          >123112, г. Москва, Пресненская наб., д. 6, стр. 2, эт. 8, пом. I,
            ком. 21</span>
        </p>
        <p>
          <span class="bold-text">Администрация</span> - сотрудники
          Администратора, осуществляющие управление Веб-сайтом
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>, выполняющие технические, организационные и другие функции по
          обеспечению функционирования сервисов Веб-сайта
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>, а также предоставляют информационную, консультационную и иную
          поддержку Веб-сайта
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>.
        </p>
        <p>
          <span class="bold-text">Контент</span> - изображения, текстовые,
          аудио- и видеоматериалы, а также иные объекты авторских и (или)
          смежных прав, а равно не являющиеся таковыми, информация и сообщения
          любого характера, доступные на Веб-сайте
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          или в Приложении Tapper.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Регистрация и учетная запись.
        </h3>
        <p>
          Для получения услуг на Веб-сайте или в Приложении, Пользователь должен
          зарегистрировать аккаунт. Регистрация осуществляется путем заполнения
          соответствующей информации (электронная форма) на Веб-сайте или в
          Приложении и подачи заявки на регистрацию аккаунта, которая должна
          быть одобрена Администрацией.
        </p>
        <p>
          Регистрация аккаунта считается подтвержденной Администрацией с момента
          уведомления Пользователя о создании его аккаунта, путем направления
          ему соответствующего электронного сообщения по реквизитам, которые
          были указаны Пользователем при регистрации. Обратите внимание, что
          некоторые услуги могут быть недоступны в определенных юрисдикциях или
          регионах или для определенных Пользователей. Мы оставляем за собой
          право изменять, модифицировать или налагать дополнительные ограничения
          по своему усмотрению в любое время на Вашу учетную запись.
        </p>
        <p>
          Информация, являющаяся обязательной к заполнению включает в себя: Имя,
          адрес электронной почты (E-mail), телефон, наименование заведения,
          создание и подтверждение пароля.
        </p>
        <p>
          Создание более одной учетной записи Пользователя для одного и того же
          лица не допускается. Учетная запись Пользователя не может быть
          передана третьим лицам.
        </p>
        <p>
          Ваша регистрация учетной записи будет считаться вашим согласием
          предоставить в случае необходимости, личную информацию для проверки
          личности. Такая информация будет использоваться для проверки личности
          Пользователя (ей), выявления фактов-следов отмывания денег,
          финансирования терроризма, мошенничества и других финансовых
          преступлений через наш сервис или для других законных целей,
          заявленных нами. Мы будем собирать, использовать и передавать такую
          ​​информацию в соответствии с нашей Политикой конфиденциальности.
          Помимо предоставления такой информации, вы соглашаетесь разрешить нам
          вести учет этой информации в течение периода, которого ваша учетная
          запись активна, и в течение пяти (5) лет после закрытия вашей учетной
          записи, в соответствии с глобальными отраслевыми стандартами хранения
          данных. Вы также разрешаете нам проводить необходимые расследования
          напрямую или через третьих лиц для проверки вашей личности или защиты
          вас и / или нас от финансовых преступлений, таких как мошенничество.
          Информация, которая нам требуется для подтверждения вашей личности,
          может включать, помимо прочего, контактную информацию, номер телефона,
          удостоверение личности государственного образца, дату рождения и
          другую информацию. Предоставляя необходимую информацию, вы
          подтверждаете, что она верна и точна. После завершения регистрации для
          вашей учетной записи в Tapper вы можете использовать различные услуги
          Tapper.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Подача заявки Пользователем на подключение к сервису Tapper
        </h3>
        <p>
          Для получения услуг на Веб-сайте или в Приложении, Пользователь должен
          отправить заявку на партнерство, путем активации функции “Подключить
          заведение” и заполнения соответствующей формы.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Рассмотрение заявки Пользователя
        </h3>
        <p>
          После поступления Вашей заявки, мы осуществляем ее рассмотрение, по
          итогам которого наш менеджер свяжется с вами по реквизитам указанным
          вами в соответствующей форме и поможет организовать внедрение Tapper.
        </p>
        <p>
          Срок рассмотрения вашей заявки не является фиксированным и может
          продолжаться неограниченное количество времени которое необходимо нам
          для проведения соответствующего анализа-экспертизы вашего заведения.
        </p>
        <p>
          После одобрения заявки и подключения Пользователя к сервисам и услугам
          Tapper, последнему присваивается статус Продавец.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Предмет
        </h3>
        <p>
          Tapper предоставляет Продавцу и Посетителю неисключительную лицензию
          на использование Сервиса Tapper, а Продавец и Посетитель использует
          Сервис согласно условиям Договора. Использование Сервиса Tapper
          регулируется настоящим Договором, правилами оказания услуг Продавца
          размещенными в конкретном Заведении, политикой конфиденциальности
          Tapper. При наличии разночтений между Договором и правилами Продавца
          применяются положения настоящего Договора.
        </p>
        <p>
          Существенные условия заключаемого Посетителем с Продавцом Договора
          указываются в описании Товара-продукта, условий его приготовления
          и/или реализации, предоставленных Продавцом.
        </p>
        <p>
          Tapper не является платежным агентом при приеме оплаты от Посетителя
          за Заказ согласно пп. 1, 4 ч. 2 ст. 1 Федерального закона от
          03.06.2009 № 103-ФЗ «О деятельности по приему платежей физических лиц,
          осуществляемой платежными агентами».
        </p>
        <p>
          При получении Товара-услуги по Заказу, Посетитель вправе по своему
          усмотрению совершить дополнительный безналичный платеж в пользу
          Официанта в качестве оценки качества услуг («Чаевые»). Максимальный
          размер Чаевых может быть ограничен.
        </p>
        <p>
          Tapper не является Продавцом, реализующим через Сервис Товары и\или
          услуги, а лишь предоставляет возможность Заказа соответствующего
          Товара-услуги у Продавца.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Безопасность учетной записи.
        </h3>
        <p>
          Пользователь обязуется не сообщать третьим лицам логин и пароль,
          указанные или полученные при регистрации. За все действия, совершаемые
          от его имени, то есть с использованием его логина и пароля,
          ответственность несет исключительно Пользователь. Вы также
          соглашаетесь нести единоличную ответственность за принятие необходимых
          мер безопасности для защиты вашей учетной записи и личной информации в
          Tapper.
        </p>
        <p>Создавая учетную запись в Tapper, вы соглашаетесь с тем, что:</p>
        <ul>
          <li>
            вы немедленно уведомите Tapper, если вам станет известно о любом
            несанкционированном использовании вашей учетной записи и пароля или
            любом другом нарушении правил безопасности;
          </li>
          <li>
            вы будете строго соблюдать все механизмы и процедуры Tapper,
            касающиеся безопасности, аутентификации, покупки-торговли,
            начисления и снятия средств;
          </li>
          <li>
            вы будете предпринимать соответствующие шаги для надлежащего выхода
            из своего аккаунта в конце каждого посещения-сессии.
          </li>
        </ul>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Запрещенное использование.
        </h3>
        <p>
          Вы соглашаетесь не загружать, не размещать, не отправлять по
          электронной почте или иным образом не передавать любые материалы,
          которые содержат программные вирусы или любой другой компьютерный код,
          файлы или программы, предназначенные для того, чтобы прерывать,
          вредить, повреждать, уничтожать или ограничивать функциональность
          любого компьютерного программного обеспечения или аппаратного
          обеспечения или оборудования, прямо или косвенно связанного с
          Веб-сайтом или Приложением. Вы соглашаетесь не вмешиваться в работу
          серверов или сетей, лежащих в основе или связанных с данным Веб-сайтом
          или Приложением а также не нарушать любые процедуры, политики или
          правила сетей, подключенных к Веб-сайту и\или Приложению.
        </p>
        <p>
          Вы соглашаетесь не сдавать в аренду, сублицензировать, переводить,
          объединять, адаптировать или изменять приложение Tapper или любую
          связанную документацию, использовать приложение Tapper только в личных
          целях (если вы являетесь потребителем) или для внутренних
          бизнес-целей, (если вы являетесь бизнесом).
        </p>
        <p>
          Вы соглашаетесь не разрешать какой-либо третьей стороне копировать,
          адаптировать, реконструировать, декомпилировать, дизассемблировать,
          изменять или исправлять ошибки в приложении Tapper полностью или
          частично, не подвергать обратному инжинирингу или создавать
          производные работы на основе всего или какой-либо части приложения
          Tapper и\или Веб-сайта, а также пытаться делать что-либо подобное.
          Приложение и Веб-сайт могут содержать определенное стороннее
          программное обеспечение, плагины или интерфейс приложений (совместно
          именуемые «Стороннее программное обеспечение»), для которых требуются
          отдельные Условия соглашения.
        </p>
        <p>
          Вы соглашаетесь не выдавать себя или пытаться выдать себя за компанию
          Tapper, сервис Tapper, сотрудника компании Tapper, другого
          пользователя или любое другое физическое или юридическое лицо
          (включая, помимо прочего, использование адресов электронной почты или
          псевдонимов, связанных с любым из вышеизложенного).
        </p>
        <p>
          Вы соглашаетесь не передавать или обеспечивать отправку любых
          рекламных или маркетинговых материалов без нашего предварительного
          письменного согласия, включая любую «нежелательную почту», «письма
          счастья», «спам» или любые другие подобные запросы.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Права интеллектуальной собственности.
        </h3>
        <p>
          Совокупность программ, данных, торговых марок, объектов
          интеллектуальной собственности в том числе объектов авторского права,
          и других объектов, используемых на Веб-сайте или в Приложении,
          являются интеллектуальной собственностью его законных правообладателей
          и охраняются законодательством об интеллектуальной собственности, а
          также соответствующими международными правовыми договорами и
          конвенциями.
        </p>
        <p>
          Любое использование элементов, которые входят в состав Веб сайта или
          Приложения (символика, текст, графические изображения, программы и
          другие объекты), кроме разрешенного в настоящем Документе, без
          разрешения является незаконным и может послужить причиной для
          судебного разбирательства и привлечения к гражданско-правовой,
          административной и уголовной ответственности в соответствии с
          законодательством.
        </p>
        <p>
          Если Вы являетесь владельцем торговой марки и/или других объектов
          интеллектуальной собственности и считаете, что материалы, размещенные
          на Веб-сайте или в Приложении, нарушают ваши права, пожалуйста,
          напишите нам на электронный адрес:
          <a href="mailto:info@foundarium.com">info@foundarium.com</a>.
        </p>
        <p>В обращении, пожалуйста, укажите следующую информацию:</p>
        <ul>
          <li>
            идентифицируйте себя (имя, адрес для переписка, электронная почта);
          </li>
          <li>
            опишите характер нарушения прав интеллектуальной собственности и
            идентифицируйте соответствующий объект интеллектуальной
            собственности на Веб-сайте или в Приложении
          </li>
        </ul>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Полагание на опубликованную информацию.
        </h3>
        <p>
          Информация, представленная на Веб-сайте
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          или через него, а также в Приложении предоставляется исключительно для
          общих информационных целей. Мы не гарантируем точность, полноту или
          полезность этой информации. Вы полагаетесь на такую ​​информацию
          исключительно на свой страх и риск. Мы снимаем с себя всякую
          ответственность и ответственность, возникающую в связи с
          использованием таких материалов вами или любым другим посетителем
          Веб-сайта и\или Приложения, или любым лицом, которое может быть
          проинформировано о его содержании.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Ссылки на веб-сайты и функции социальных сетей.
        </h3>
        <p>
          Вы можете ссылаться на наш Веб-сайт, при условии, что вы делаете это
          честно и законно, не наносите ущерба нашей репутации или не
          пользуетесь ею, но вы не должны устанавливать ссылку таким образом,
          чтобы предлагать какую-либо форму ассоциации или одобрение с нашей
          стороны без нашего явного письменного согласия.
        </p>
        <p>
          Если на веб-сайте есть ссылки на другие сайты и ресурсы,
          предоставленные третьими сторонами, эти ссылки предоставляются только
          для вашего удобства. Сюда входят ссылки, содержащиеся в рекламных
          объявлениях, включая рекламные баннеры и спонсорские ссылки. Мы не
          контролируем содержимое этих сайтов или ресурсов и не несем
          ответственности за них или за любые убытки или ущерб, которые могут
          возникнуть в результате их использования вами. Если вы решите получить
          доступ к любому из сторонних веб-сайтов, связанных с этим Веб-сайтом,
          вы делаете это исключительно на свой страх и риск и в соответствии с
          условиями использования таких веб-сайтов.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Ограничение ответственности.
        </h3>
        <p>
          Tapper будет прилагать все разумные усилия для надлежащей работы
          Веб-сайта
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          и Приложения, однако не будет нести ответственности перед
          Пользователем или третьими лицами за прямые и/или косвенные убытки,
          включая упущенную выгоду или потерянные данные, вред чести,
          достоинству или деловой репутации, понесенные в связи с использованием
          Веб-сайта
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          или Приложения, или невозможности их использования, или
          несанкционированного доступа к коммуникациям Пользователя третьими
          лицами, или невозможность получения услуг, в частности, в следующих
          случаях:
        </p>
        <ul>
          <li>
            при наличии технологических неисправностей каналов связи общего
            пользования или потери доступа к сети Интернет - до устранения
            неисправностей или восстановления доступа, соответственно;
          </li>
          <li>
            при наличии признаков несанкционированного доступа к управлению
            учетной записью Пользователя - на срок действия таких обстоятельств;
          </li>
          <li>
            при нарушении безопасности оборудования или неисправности
            оборудования (персонального компьютера,смартфона), который
            используется Пользователем для получения услуг на Веб-сайте
            <a
              href="https://tapper.cloud"
              target="_blank"
            >https://tapper.cloud</a>
            (доступ к Аккаунту).
          </li>
        </ul>
        <p>
          Tapper не несет ответственности за любые убытки, возникшие у
          Пользователя в результате того, что Пользователь не ознакомился или
          несвоевременно ознакомился с условиями изложенными в настоящем
          соглашении.
        </p>
        <p>Tapper не несет ответственности за:</p>
        <ul>
          <li>
            недостоверность размещенной в Сервисе информации о составе
            Товаров-услуг;
          </li>
          <li>сроки приготовления Товаров-услуг и/или исполнения Доставки;</li>
          <li>
            негативные последствия приема Товаров-услуг для жизни и здоровья
            Посетителя;
          </li>
          <li>
            невозможность использования Сервиса по причинам, не зависящим от
            Tapper;
          </li>
          <li>
            любые действия и/или бездействия поставщиков услуг, сервисов, сетей,
            программного обеспечения или оборудования, в том числе и при
            проведении оплаты Заказа;
          </li>
        </ul>
        <p>
          Tapper не является уполномоченной организацией по смыслу Закона РФ №
          2300-1 от 07.02.1992 г. «О защите прав потребителей», и не
          осуществляет рассмотрение и удовлетворение претензий Посетителей в
          отношении Товаров-услуг и/или Доставки ненадлежащего качества, Заказ
          которых оформлен Посетителем у Продавца с помощью Сервиса Tapper.
        </p>
        <p>
          Пользователь согласен с тем, что условия ограничения ответственности,
          изложенные выше, отображают умное и справедливое распределение рисков.
          Во избежании неверного толкования положений об ограничении
          ответственности, стороны прямо отмечают, что Пользователю не будет
          отказано в любых законных правах или правах, которые возникают в связи
          с договорными отношениями между Пользователем и Tapper.
        </p>
        <p>
          Стороны освобождаются от ответственности за задержку и/или частичное
          или полное неисполнение обязательств по настоящему Соглашению, если
          это неисполнение явилось следствием обстоятельств непреодолимой силы
          (Форс-мажор), которые находятся вне контроля сторон, и преодолеть
          которые разумными методами невозможно.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Отказ от гарантий.
        </h3>
        <p>
          Tapper не является вашим агентом или советником и не имеет фидуциарных
          отношений или обязательств перед вами, в связи с любыми сделками или
          другими решениями или действиями, совершенными вами с использованием
          Сервисов Tapper. Никакие сообщения или информация, предоставляемые вам
          Веб-сайтом
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>, не предназначены и не должны рассматриваться или толковаться как
          инвестиционные, финансовые, торговые или любые другие советы. Если
          иное не указано в настоящих Условиях. Вы также осознаете и понимаете,
          что мы не можем гарантировать и не гарантируем, что файлы, доступные
          для загрузки из Интернета или с веб-сайтов, не будут содержать вирусов
          или другого деструктивного кода. Вы несете ответственность за
          внедрение достаточных процедур и контрольных точек для удовлетворения
          ваших конкретных требований к антивирусной защите и точности ввода и
          вывода данных, а также за поддержание внешних по отношению к нашему
          сайту средств для восстановления любых потерянных данных.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Географические ограничения.
        </h3>
        <p>
          Администратор веб-сайта
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          находится в Российской Федерации. Мы не делаем никаких заявлений о
          том, что Веб-сайт или какой-либо контент доступен или уместен за
          пределами Российской Федерации. Доступ к Веб-сайту
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          может быть незаконным для определенных лиц или в определенных странах.
          Если вы заходите на Веб-сайт
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          из-за пределов Российской Федерации, то это будет происходить по Вашей
          собственной инициативе и Вы несете ответственность за соблюдение
          местных законов.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Возмещение.
        </h3>
        <p>
          Вы соглашаетесь оградить Tapper, его аффилированных лиц, лицензиаров и
          поставщиков услуг, а также его должностных лиц, директоров,
          сотрудников, подрядчиков, агентов, правопреемников от любых претензий
          и обязательств, убытков, судебных решений, издержек, расходов или
          гонораров (включая разумные гонорары адвокатам), возникающие в
          результате или связанные с нарушением вами настоящих Условий
          использования или использованием вами Веб-сайта
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>, включая, помимо прочего, любое использование содержание, услуги или
          продукта, кроме тех, которые прямо разрешены в настоящих Условиях
          использования.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Регулирующее законодательство и юрисдикция.
        </h3>
        <p>
          Все вопросы, связанные с Веб-сайтом
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          и настоящими Условиями использования, а также любые споры или
          претензии, возникающие из них или связанные с ними (в каждом случае,
          включая внедоговорные споры или претензии), регулируются и толкуются в
          соответствии с внутренним законодательством Российской Федерации, без
          осуществления любого выбора или положения или правила коллизионного
          права.
        </p>
        <p>
          Любой судебный иск или судебное разбирательство, вытекающие из
          настоящих Условий использования или Веб-сайта
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          или связанных с ним лиц, должно быть возбуждено исключительно в
          Российской Федерации, хотя мы сохраняем за собой право подать любой
          иск, или инициировать судебное разбирательство против вас за нарушение
          настоящих Условий использования в стране Вашего проживания или любой
          другой соответствующей стране. Вы отказываетесь от любых возражений
          против осуществления юрисдикции над вами такими судами и против
          рассмотрения дела в таких судах.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Другие условия.
        </h3>
        <p class="vLandingPoliticModal__text--underline">
          Использование Приложения во время вождения
        </p>
        <p>
          Всегда едьте осторожно и в соответствии с дорожными условиями и
          применимыми правилами дорожного движения. Любое прямое взаимодействие
          с Приложением запрещено во время вождения. Если вы хотите
          взаимодействовать с Приложением во время вождения, вы должны сначала
          остановить свой автомобиль надлежащим и законным образом и в нужном
          месте. Вы признаете, что несете ответственность за соблюдение всех
          применимых законов, когда вы используете Приложение любым способом во
          время вождения, и что вы используете Приложение любым образом во время
          вождения на свой страх и риск.
        </p>
        <p>
          Tapper НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА ЛЮБОЙ ПРЯМОЙ ИЛИ КОСВЕННЫЙ УЩЕРБ, В
          ТОМ ЧИСЛЕ НЕСЧАСТНЫЙ СЛУЧАЙ, ТРАВМУ ИЛИ ДРУГИЕ УБЫТКИ СВЯЗАННЫЕ С
          ИСПОЛЬЗОВАНИЕМ ПРИЛОЖЕНИЯ ВО ВРЕМЯ ВОЖДЕНИЯ.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          Реквизиты.
        </h3>
        <p class="bold-text">
          Общество с ограниченной ответственностью «ФАУНДАРИУМ»,
        </p>
        <p>125212, г. Москва, Головинское шоссе,</p>
        <p>д. 5, корп./ст. 1, кв./оф. 2136</p>
        <p>ОГРН: 1217700404206</p>
        <p>
          Email: <a href="mailto:info@foundarium.com">info@foundarium.com</a>
        </p>
      </div>
      <h2 class="vLandingPoliticModal__title">
        Политика конфиденциальности и обработки персональных данных Tapper
      </h2>
      <div class="vLandingPoliticModal__block">
        <p
          v-if="!oldConditions"
          class="vLandingPoliticModal__text vLandingPoliticModal__text--underline"
        >
          Редакция от “10” ноября 2021 г.
        </p>

        <p
          v-if="oldConditions"
          class="vLandingPoliticModal__text vLandingPoliticModal__text--underline vLandingPoliticModal__text--link"
          @click="toggleOldConditions(false)"
        >
          Редакция от “10” ноября 2021 г.
        </p>

        <p
          v-if="!oldConditions"
          class="vLandingPoliticModal__text vLandingPoliticModal__text--underline vLandingPoliticModal__text--link"
          @click="toggleOldConditions(true)"
        >
          Редакция от “14” октября 2021 г.
        </p>

        <p
          v-if="oldConditions"
          class="vLandingPoliticModal__text vLandingPoliticModal__text--underline"
        >
          Редакция от “14” октября 2021 г.
        </p>
        <p class="vLandingPoliticModal__text">
          Эта Политика конфиденциальности описывает, как Tapper (Далее -
          “Компания” или “<i>мы</i>”,“<i>нами</i>”, “<i>нас</i>”) использует и
          передает Информацию, собранную нами или предоставленную Вами во время
          посещения Веб-сайта, который размещен по адресу:
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          или в Приложении Tapper).
        </p>
        <p class="vLandingPoliticModal__text">
          Владельцем персональных данных является Общество с ограниченной
          ответственностью «ФАУНДАРИУМ» («Tapper»), ОГРН: 1217700404206
          расположенная по адресу: Российская Федерация, 125212, г. Москва,
          Головинское шоссе, д. 5, корп./ст. 1, кв./оф. 2136
        </p>
        <p class="vLandingPoliticModal__text">
          Эта Политика конфиденциальности также разъясняет Ваши права и
          возможности в отношении Ваших персональных Данных, а также то, как Вы
          можете связаться с нами, чтобы внести изменения в Ваши персональные
          данные или получить ответы на вопросы, которые могут возникнуть у Вас
          относительно наших практик в сфере конфиденциальности. Положения
          настоящей Политики Конфиденциальности могут быть изменены в
          дальнейшем. Каждый раз, когда мы вносим изменения в эту Политику
          конфиденциальности, мы опубликуем ее новую редакцию на этой странице и
          укажем дату новой редакции.
        </p>
        <p class="vLandingPoliticModal__text">
          Пожалуйста, внимательно прочитайте положения этой Политики
          Конфиденциальности и свяжитесь с нами по электронной почте, указанной
          в этом документе, если у вас возникнут вопросы. Используя Веб-сайт
          и\или Приложение, получая услуги на Веб-сайте
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          или проставляя соответствующую отметку (например, нажимая на
          “согласен”) вы соглашаетесь со всеми условиями настоящей Политики
          конфиденциальности, подтверждаете свое ознакомление с изложенными
          здесь условиями и даете нам свое свободное и однозначное согласие
          использовать Ваши Персональные данные в соответствии с условиями
          настоящей Политики конфиденциальности.
        </p>
      </div>
      <div class="vLandingPoliticModal__block">
        <h3 class="vLandingPoliticModal__subtitle">
          ОПРЕДЕЛЕНИЕ ТЕРМИНОВ
        </h3>
        <p>
          <span class="bold-text">Владелец персональных данных</span> -
          юридическое лицо, которое определяет цель обработки персональных
          данных, устанавливает состав этих данных и процедуры их обработки,
          если иное не определено законом.
        </p>
        <p>
          <span class="bold-text">Персональные данные</span> - сведения или
          совокупность сведений о физическом лице, которое идентифицировано или
          может быть конкретно идентифицировано.
        </p>
        <p>
          <span class="bold-text">Неперсональные Данные</span> - информация и
          сведения, которые сами по себе не позволяют непосредственно
          идентифицировать конкретное лицо.
        </p>
        <p>
          <span class="bold-text">Информация</span> - как Персональные, так и не
          персональные Данные.
        </p>
        <p>
          <span class="bold-text">Пользователь</span> - физическое лицо,
          получающее доступ к Веб-сайту
          <a
            href="https://tapper.cloud"
            target="_blank"
          >https://tapper.cloud</a>
          и\или Приложению, пользующееся им и/или осуществляющее заказ
          соответствующих услуг.
        </p>
        <p>
          <span class="bold-text">Обработка</span> - любые действия с
          персональными данными в том числе, но не исключительно: доступ,
          предоставление, распространение, сбор, систематизация, хранение,
          накопление, запись, передача, блокирование, удаление, уточнение,
          обновление и/или изменение, обезличивание и другие способы
          использования персональных данных, осуществляемые Компанией.
        </p>
        <p>
          <span class="bold-text">Закон</span> - Федеральный закон от 27.07.2006
          N 152-ФЗ (ред. от 02.07.2021) "О персональных данных"
        </p>
      </div>
      <ol>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            ОБЩИЕ ПОЛОЖЕНИЯ
          </h3>
          <p class="vLandingPoliticModal__text">
            Мы обрабатываем Ваши Персональные данные в соответствии с
            Федеральным законом N 152-ФЗ, от 27.07.2006, исключительно для
            выполнения Вашего заказа, заявки, поручения и предоставления
            необходимой информации о состоянии его выполнения, регистрации
            учетной записи / аккаунта, участия в рекламных активностях компании,
            при обращении к клиентской поддержке, а также когда Вы
            подписываетесь на наши рассылки, участвуете в опросах или оставляете
            отзывы о наших услугах.
          </p>
          <p class="vLandingPoliticModal__text">
            Информация предоставленная Вами является конфиденциальной и не
            передается третьим лицам, кроме необходимости выполнения Вашего
            заказа, заявки, поручения, а также других случаев, предусмотренных
            законодательством.
          </p>
          <p class="vLandingPoliticModal__text">
            Положения настоящей Политики Конфиденциальности применяются только к
            Веб-сайту и\или Приложению Tapper. Компания не контролирует и не
            несет ответственности за сайты третьих лиц, на которые Пользователь
            может перейти по ссылками, доступным на Сайте.
          </p>
          <p class="vLandingPoliticModal__text">
            Пожалуйста, обратите внимание, что Компания не проверяет
            достоверность информации предоставленной Пользователем.
          </p>
          <p class="vLandingPoliticModal__text">
            Информация о Вас обрабатывается с момента Вашего входа на сайт и
            начала пользования Сайтом, а также с момента получения Компанией
            Ваших персональных данных, направленных по электронной почте, через
            сайт или предоставленных через другие средства коммуникаций.
          </p>
        </li>
        <li>
          <h3
            id="cookie"
            ref="cookie"
            class="vLandingPoliticModal__subtitle"
          >
            ИНФОРМАЦИЯ, КОТОРУЮ МЫ МОЖЕМ СОБИРАТЬ ИЛИ ПОЛУЧАТЬ
          </h3>
          <p class="vLandingPoliticModal__text">
            Компания собирает и использует только те персональные данные
            пользователей, которые необходимы для выполнения их заказа услуги,
            и/или осуществления регистрации на Веб-сайте и\или в Приложении, с
            целью создания учетной записи / аккаунта. Для Пользователя - это
            имя, фамилия, адрес электронной почты.
          </p>
          <p class="vLandingPoliticModal__text">
            Другая информация может быть предоставлена ​​пользователем
            исключительно на его усмотрение (кроме случаев, если такая
            информация требуется для обеспечения возможности выполнения договора
            или ее предоставление предусмотрено законодательством).
          </p>
          <p class="vLandingPoliticModal__text">
            Кроме того, мы используем технологию “cookie” для сбора
            дополнительных данных об использовании сайта и для улучшения работы
            сайта, повышения качества наших услуг.
          </p>
          <p class="vLandingPoliticModal__text">
            Cookie-файлы - это небольшие файлы, которые Сайт, сервисы сетевого
            программного обеспечения или поставщики услуг, помещают в устройство
            через веб-браузер (если это разрешено Вами), что позволяет сайту или
            поставщикам услуг распознавать Ваш браузер и хранить и запоминать
            определенную информацию.
          </p>
          <p class="vLandingPoliticModal__text">
            С помощью cookie-файлов мы можем обрабатывать такую Информацию как:
          </p>
          <p class="vLandingPoliticModal__text">
            Данные об устройстве: аппаратная модель, уникальные идентификаторы
            устройства, MAC-адрес, IP- адрес, версия операционной системы и
            настройки устройства;
          </p>
          <p class="vLandingPoliticModal__text">
            Информацию из Log-файлов: Время и продолжительность использования
            сайта, поисковые запросы, информация о местонахождении и любая
            другая информация, хранящаяся в Cookie-файлах, что позволяет
            однозначно идентифицировать Ваш браузер или аккаунт;
          </p>
          <p class="vLandingPoliticModal__text">
            Другие данные: данные об использовании Вами сайта, которые мы можем
            обрабатывать, если Вы посещаете или используете сторонние веб-сайты
            или приложения для связи с нами, о том, как Вы взаимодействуете с
            контентом, размещенным на Веб-сайте и\или в Приложении.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы используем Cookie-файлы для многих важных вещей - например, чтобы
            помочь Вам войти в систему и чтобы улучшить Ваш опыт пользования
            нашим Веб-сайтом и\или Приложением или услугами. Мы можем
            использовать Cookie-файлы для того, чтобы лучше понять как Вы
            взаимодействуете с Веб-сайтом и\или Приложением, отслеживать общее
            количество пользователей Веб-сайта и\или Приложения и маршрутизацию
            веб-трафика на Веб-сайте и\или в Приложении, совершенствовать
            Веб-сайт и\или Приложение и наши услуги, чтобы понять Ваши
            предпочтения на основе предыдущей или текущей активности на
            Веб-сайте и\или в Приложении, что позволит нам предоставлять Вам
            услуги высшего качества.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы не собираем и просим Вас не предоставлять нам любую Информацию,
            по обработке которой Законом установлены определенные ограничения и
            требования, а именно - информацию о расовом или этническом
            происхождении, о политических, религиозных или мировоззренческих
            убеждениях, о членстве в политических партиях и профессиональных
            союзах, судимостей по уголовным делам или о подозрении в совершении
            преступлений по уголовным делам, а также данные, касающиеся
            здоровья, половой жизни, биометрических и генетических данных
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            КАК МЫ МОЖЕМ ИСПОЛЬЗОВАТЬ ПЕРСОНАЛЬНЫЕ ДАННЫЕ
          </h3>
          <p class="vLandingPoliticModal__text">
            Мы можем использовать Информацию, собранную нами, как описано выше,
            в следующих целях:
          </p>
          <p class="bold-text">
            Договорные отношения.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы можем обрабатывать Ваши Персональные Данные для выполнения
            договорных обязательств - передачи информации о Вас Поставщикам
            услуг, для оформления Вашего заказа, заявки, поручения.
          </p>
          <p class="bold-text">
            Запросы и другие коммуникации.
          </p>
          <p class="vLandingPoliticModal__text">
            В случае, если Вы связались с нами через наши контактные формы и /
            или по электронной почте / телефону, мы можем обрабатывать
            Информацию, которую Вы нам предоставили, чтобы иметь возможность
            ответить на ваш вопрос, жалобу или запрос.
          </p>
          <p class="bold-text">
            Маркетинг.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы можем использовать Информацию, полученную от Вас (например, вашу
            электронную почту) в маркетинговых целях. Например, подписавшись на
            нашу новостную рассылку, Вы сможете получать публикации и объявления
            прямо на свою электронную почту. Мы можем посылать Вам рекламные
            материалы, касающиеся наших услуг, акционных предложений, которые,
            по нашему мнению, могут Вас заинтересовать.
          </p>
          <p class="vLandingPoliticModal__text">
            Вы можете отказаться от получения наших маркетинговых электронных
            рассылок (как полностью, так и частично), нажав на ссылку “отменить
            подписку” в электронных письмах, которые Вы получаете от нас. Кроме
            того, для того, чтобы отменить подписку, Вы можете в любой момент
            связаться с нами отправив нам сообщение на адрес электронной почты,
            указанный в настоящей Политике конфиденциальности.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы также можем использовать Информацию, полученную с помощью
            Cookie-файлов с целью проведение маркетинговых исследований, для
            анализа характеристик пользователей сайта, оценки эффективности
            нашей маркетинговой связи и для приспособления к существующим
            тенденциям, для планирование наших будущих маркетинговых кампаний,
            бизнес-аналитики, персонализации сервисов и коммуникаций для Вас.
          </p>
          <p class="bold-text">
            Защита наших интересов, безопасность.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы можем использовать Ваши персональные данные, когда считаем
            необходимым принятие мер по предотвращению возможного наступления
            ответственности, для осуществления расследований и защиты компании
            от любых претензий или обвинений третьих лиц, расследований и защиты
            компании от мошенничества, защиты безопасности или целостности
            Веб-сайта и\или Приложения и защиты прав собственности нашей
            Компании, пользователей и/или партнеров.
          </p>
          <p class="bold-text">
            Соответствие требованиям Закона.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы также можем использовать / раскрывать Вашу Информацию, собранную
            нами, чтобы соответствовать законодательным требованиям, отраслевым
            стандартам и нашей политике. Мы можем раскрывать Вашу Информацию в
            ситуациях, которые, по нашему мнению: (1) является чрезвычайными
            ситуациями,связанными с потенциальной угрозой физической
            безопасности любого лица или имущества, если мы считаем, что Ваша
            Информация любым образом связана с такой угрозой; (2) является
            связанной с незаконным или неподходящим, по нашему мнению,
            использованием Сайта, Приложения и/или услуг.
          </p>
          <p class="bold-text">
            Обработка Информации включает:
          </p>
          <p class="vLandingPoliticModal__text">
            Сбор, накопление, хранение, адаптирование, проверку, изменение,
            восстановление, использование, обезличивание, уничтожение
            персональных данных, в том числе с использованием информационных
            (Автоматизированных) систем. Сбор персональных данных может
            происходить в устной, письменной и / или электронной форме, путем
            запросов или самостоятельного предоставления Вами необходимых
            сведений и документов. Персональные данные накапливаются и хранятся
            в письменной и / или электронной форме.
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            КАК МЫ МОЖЕМ ПЕРЕДАВАТЬ ПЕРСОНАЛЬНЫЕ ДАННЫЕ
          </h3>
          <p class="vLandingPoliticModal__text">
            Мы уделяем особое внимание конфиденциальности и защите Ваших
            персональных данных. Информация предоставленная Вами является
            конфиденциальной и не передается третьим лицам, кроме необходимости
            выполнения или предоставления услуг (и), а также случаев,
            предусмотренных законодательством.
          </p>
          <p class="vLandingPoliticModal__text">
            Пользователь соглашается с тем, что Компания имеет право передавать
            персональные данные пользователей Поставщикам, - исключительно с
            целью выполнения соответствующей услуги.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы можем раскрывать Ваши Персональные Данные, если этого требует
            Закон или с целью выполнения юридического обязательства или если мы
            уверены, что такие действия необходимы для: расследования,
            реагирования и защиты относительно претензий; если это необходимо в
            судебном процессе (в том числе и для вызова в суд) для защиты прав и
            собственности Компании или третьих лиц; для предотвращения
            возможного наступления ответственности; в целях общественной
            безопасности или безопасности отдельных лиц; с целью предотвращения
            или прекращения любой незаконной, неэтичной, мошеннической,
            оскорбительной и иной деятельности, которая может привести к
            негативным юридическим последствиям; для целостности и безопасности
            Веб-сайта и\или Приложения, наших услуг любого оборудования,
            используемого в работе Веб-сайта и\или Приложения при предоставлении
            услуг; для соблюдения требований действующего законодательства.
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            КАК МЫ ХРАНИМ И ЗАЩИЩАЕМ ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ
          </h3>
          <p class="vLandingPoliticModal__text">
            Защита ваших персональных данных является чрезвычайно важной для
            нас, поэтому мы принимаем все необходимые меры для этого. Мы храним
            персональные данные пользователей в безопасных условиях. Ваши
            Персональные данные защищены от несанкционированного доступа,
            раскрытия, использования, изменения или уничтожения.
          </p>
          <p class="vLandingPoliticModal__text">
            Компания на постоянной основе осуществляет контроль за принятыми
            мерами по обеспечению безопасности.
          </p>
          <p class="vLandingPoliticModal__text">
            Мы будем хранить Ваши Персональные Данные в течение срока, когда они
            необходимы для предоставления Вам доступа к Сайту и/или для
            выполнения наших юридических обязательств (выполнение Ваших заказов
            и предоставления услуг, налоговой и финансовой отчетности.),
            разрешении споров и соответствия нашей политики.
          </p>
          <p class="vLandingPoliticModal__text">
            Срок хранения определяется с учетом типа собранной Информации, и
            цели, ради которой она собиралась в зависимости от ситуации и
            необходимости удаления устаревшей или ненужной Информации в
            кратчайшие сроки.
          </p>
          <p class="vLandingPoliticModal__text">
            После того, как Вы прекращаете пользоваться Веб-сайтом и\или
            Приложением и нашими услугами, путем удаления своей учетной записи
            на Сайте и\или в Приложении, Ваши Персональные Данные также
            автоматически удаляются (кроме случаев, когда мы вынуждены хранить
            такие данные для выполнения обязанностей установленных действующим
            законодательством и этими Правилами использования).
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            ВАШИ ПРАВА
          </h3>
          <p class="vLandingPoliticModal__text">
            Относительно Ваших персональных данных, Вы имеете право:
          </p>
          <ol>
            <li>
              в любое время обратиться к владельцу персональных данных с любыми
              вопросами и жалобами по обработке персональных данных, а также
              спросить обрабатываются ли Ваши персональные данные и о содержании
              таких персональных данных;
            </li>
            <li>на бесплатный доступ к своим персональным данным;</li>
            <li>
              в любое время просить владельца персональных данных отменить или
              ограничить обработку персональных данных, а также требовать
              внесения изменений в персональные данные, если в них имеются
              неточности или они потеряли актуальность;
            </li>
            <li>
              в любое время просить владельца персональных данных удалить
              персональные данные в том объеме, в котором они больше не нужны
              для достижения указанных в этом уведомлении целей или других
              целей, в которых владельцу необходимо продолжать их обработку, или
              когда владелец персональных данных больше не имеет законного права
              обрабатывать их;
            </li>
            <li>
              в любое время отозвать свое согласие на обработку персональных
              данных;
            </li>
            <li>обращаться с жалобами на обработку персональных данных;</li>
            <li>
              знать об источниках сбора, местонахождении персональных данных,
              цели их обработки, местонахождении владельца или распорядителя
              персональных данных или дать соответствующее поручение о получении
              этой информации уполномоченным им лицам, кроме случаев,
              установленных законом;
            </li>
            <li>
              получать информацию об условиях предоставления доступа к
              персональным данным, включая информацию о третьих лицах, которым
              передаются персональные данные;
            </li>
            <li>
              предъявлять мотивированное требование владельцу с возражением
              против обработки своих персональных данных;
            </li>
            <li>
              на защиту своих персональных данных от незаконной обработки и
              случайной потери, уничтожения, повреждения в связи с умышленным
              сокрытием, непредоставлением или несвоевременным их
              предоставлением, а также на защиту от предоставления сведений,
              которые являются недостоверными или порочащих честь, достоинство и
              деловую репутацию физического лица;
            </li>
            <li>
              применять средства правовой защиты в случае нарушения
              законодательства о защите персональных данных;
            </li>
            <li>
              вносить оговорки об ограничении права на обработку своих
              персональных данных во время предоставления согласия;
            </li>
            <li>
              знать механизм автоматической обработки персональных данных;
            </li>
            <li>
              на защиту от автоматизированного решения, которое имеет для него
              правовые последствия.
            </li>
          </ol>
          <p class="vLandingPoliticModal__text">
            Если Вы хотите воспользоваться любым из вышеуказанных прав или
            получить дополнительную информацию, пожалуйста, свяжитесь с нами по
            реквизитам, которые указаны ниже в этой Политике конфиденциальности.
          </p>
          <p class="vLandingPoliticModal__text">
            Однако, пожалуйста, обратите внимание, что эти права не являются
            абсолютными и могут несколько ограничиваться нормативными
            требованиями и нашими законными интересами.
          </p>
          <p class="vLandingPoliticModal__text">
            Чтобы ответить на Ваш запрос, мы должны идентифицировать Вашу
            личность, чтобы Ваша Информация не была предоставлена
            ​​неуполномоченному лицу.
          </p>
          <p class="vLandingPoliticModal__text">
            Компания в течение десяти дней с момента получения Вашего запроса
            должна предоставить Вам информацию о действиях, предпринятых в связи
            с Вашим запросом. Срок предоставления окончательного ответа на Ваш
            запрос - до 30 дней с момента получения запроса. Однако для третьих
            лиц при ответе на запрос срок может быть продлен до 45 дней с
            момента получения запроса.
          </p>
          <p class="vLandingPoliticModal__text">
            В случае, если Вы узнали, что кто-то противоправно предоставил нам
            Ваши Персональные Данные сообщите нам об этом как можно скорее по
            контактным данным, указанным в настоящей Политике
            конфиденциальности. По Вашему запросу мы удалим Ваши Персональные
            Данные в кратчайшие сроки, но в любом случае не позднее одного
            месяца со дня получения Вашего запроса. Мы сохраним только такие
            копии Информации, которые могут быть необходимы нам с целью
            соблюдения требований Закона для таких случаев.
          </p>
          <p class="vLandingPoliticModal__text">
            Кроме того, Вы можете в любой момент изменить (обновить, дополнить,
            удалить) предоставленные Вами Персональные данные или их часть через
            учетную запись (если вы являетесь зарегистрированным Пользователем
            Веб-сайта и\или Приложения) или обратившись к нам с соответствующим
            запросом.
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            ДЕТИ
          </h3>
          <p class="vLandingPoliticModal__text">
            Мы понимаем насколько важным является принятие дополнительных мер
            для защиты приватности и безопасности детей, пользующихся Сайтом.
            Детям до 18 лет не разрешается использовать наш Веб-сайт и\или
            Приложение и получать наши услуги, если их родители не дали на это
            своего согласия, что должно подтверждаться соответствующими
            доказательствами. Мы не собираем целенаправленно Персональные данные
            детей в возрасте до 18 лет. Если мы узнаем, что нами были получены
            Персональные Данные ребенка в возрасте до 18 лет, за исключением
            вышеупомянутых обстоятельств, мы примем все необходимые меры для
            того, чтобы удалить такую Информацию как можно скорее, только если
            мы не обязаны хранить такие данные в целях соблюдения требований
            закона.
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            ОБНОВЛЕНИЕ ЭТОЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
          </h3>
          <p class="vLandingPoliticModal__text">
            Каждый раз, когда мы вносим изменения в эту Политику
            конфиденциальности, новая версия будет доступна на Веб-сайте и в
            Приложении с пометкой “Новая редакция от ... “.
          </p>
          <p class="vLandingPoliticModal__text">
            Вы можете получить доступ к новой редакции Политики
            Конфиденциальности с того самого дня, когда она вступает в силу,
            поэтому у Вас есть время, чтобы принять решение о дальнейшем
            пользовании сайтом и для направления своих возражений относительно
            изменений в обработке Ваших персональных данных. Проект новой
            Политики Конфиденциальности может быть опубликован заранее.
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            ПРАВА ТРЕТЬИХ ЛИЦ
          </h3>
          <p class="vLandingPoliticModal__text">
            Для обеспечения выполнения обязательств перед пользователями,
            Компания может осуществлять передачу данных о Пользователях
            Поставщикам и наоборот, но исключительно в пределах как это
            необходимо для выполнения соответствующих услуг, которые
            осуществлены в на Веб-сайте и\или в Приложении.
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            ВОПРОСЫ И ЖАЛОБЫ
          </h3>
          <p class="vLandingPoliticModal__text">
            Если у Вас возникли вопросы, беспокойства или жалобы относительно
            наших методов сбора и обработки Ваших Персональных данных, или если
            у Вас есть какие-то предложения или вопросы по этой Политики
            Конфиденциальности, свяжитесь с нами:
          </p>
        </li>
        <li>
          <h3 class="vLandingPoliticModal__subtitle">
            КАК С НАМИ СВЯЗАТЬСЯ
          </h3>
          <p>Наши контактные данные:</p>
          <p class="bold-text">
            Общество с ограниченной ответственностью «ФАУНДАРИУМ»,
          </p>
          <p>125212, г. Москва, Головинское шоссе,</p>
          <p>д. 5, корп./ст. 1, кв./оф. 2136</p>
          <p>ОГРН: 1217700404206</p>
          <p>
            Email: <a href="mailto:info@foundarium.com">info@foundarium.com</a>
          </p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'VLandingPoliticModal',
  emits: ['togglePoliticModal'],
  data() {
    return {
      oldPolitics: false,
      oldConditions: false,
    }
  },
  methods: {
    ...mapActions('modalPolitic', ['CHANGE_MODAL_POLITIC']),
    togglePoliticModal() {
      this.oldPolitics = false
      this.oldConditions = false
      this.CHANGE_MODAL_POLITIC()
    },

    toggleOldPolitics() {
      this.oldPolitics = true
    },

    toggleOldConditions(data) {
      this.oldConditions = data
    },
  },
}
</script>
<style lang="scss">
.vLandingPoliticModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #212129;
    opacity: 0.95;
    z-index: 15;
  }
  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 280px;
    width: 100%;
    max-width: 750px;
    padding: 24px 24px;
    max-height: 100vh;
    font-family: 'PTRootUI';
    font-size: 16px;
    line-height: 20px;
    z-index: 20;
    transform: translate(-50%, -50%);
    background-color: #fff;
    overflow-y: auto;
    scrollbar-color: #cacaca #fff !important;
    @media (max-width: 769px) {
      width: 80%;
      height: 80%;
      background-color: #fff;
    }
  }
  &__title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Stolzl';
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  &__block {
    margin-bottom: 20px;
  }
  &__text,
  p {
    margin-bottom: 16px;
    margin-bottom: 5px;
  }
  &__text--underline {
    text-decoration: underline;
  }

  &__text--link {
    color: rgb(0, 0, 255);
    cursor: pointer;
  }
  &__subtitle {
    display: inline-block;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: 'Stolzl';
    font-weight: 500;
  }
  ul {
    padding-left: 20px;
  }
  ol {
    counter-reset: item;
    padding-left: 0;
    margin-top: 0;
  }
  ul li {
    list-style-type: disc;
  }
  ol li {
    list-style: none;
  }
  a {
    text-decoration: underline;
    color: blue;
  }
  .bold-text {
    font-weight: 700;
  }
  ol li::before {
    font-weight: 600;
    margin-right: 5px;
    content: counters(item, '.') '. ';
    counter-increment: item;
  }
}
</style>
