<template>
  <router-link
    v-if="success"
    :to="`/${linkName}`"
    class="VMenuProfileLink"
    :class="{
      resize: resize,
    }"
    @click="selectMenu"
  >
    <div class="VMenuProfileLink__help">
      {{ label }}
    </div>
    <slot />
    {{ label }}
    <div
      v-if="desc"
      class="VMenuProfileLink__desc"
    >
      {{ desc }}

      <span
        v-if="desc === 'Карта не привязана'"
        class="VMenuProfileLink__cloud-circle"
      />
    </div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="vProfileMenu__arrow-right"
    >
      <path
        d="M8 10L12 14L16 10"
        stroke="#80869A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </router-link>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'VMenuProfileLink',
  props: {
    label: {
      type: String,
      required: true,
      default: 'Link',
    },
    activeLink: {
      type: Object,
      required: false,
      default: () => {
        code: ''
      },
    },
    linkName: {
      type: String,
      required: false,
      default: '',
    },
    currentRole: {
      type: String,
      required: true,
      default: 'manager',
    },
    roles: {
      type: Array,
      required: false,
      default: () => [],
    },
    resize: {
      type: Boolean,
      required: true,
      default: true,
    },
    desc: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['selectMenu'],
  computed: {
    success() {
      return (
        this.roles[0] === 'all' ||
        this.roles.some((i) => i === this.currentRole)
      )
    },
  },
  methods: {
    ...mapActions('menu', ['CHAANGE_ACTIVE_PROFILE_MENU']),
    selectMenu() {
      const currentUrl = this.$route.path.slice(1)
      this.CHAANGE_ACTIVE_PROFILE_MENU(0)

      if (this.linkName === currentUrl) return

      this.$emit('selectMenu', { code: this.linkName, name: this.label })
    },
  },
}
</script>
<style lang="scss">
.VMenuProfileLink {
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid #edeef1;
  height: 56px;
  font-size: 17px;
  color: #010d35;
  transition: color 0.2s;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    overflow: initial;
  }
  @media (max-width: 769px) {
    font-size: 17px !important;
  }
  svg {
    @media (max-width: 769px) {
      stroke: #80869a;

      &.svgFill {
        stroke: none;
        fill: #80869a;
      }
    }
  }
  &__help {
    position: absolute;
    top: 50%;
    left: calc(100% + 25px);
    transform: translateY(-50%);
    background: #fff;
    color: #80869a;
    border: 1px solid #e6e7eb;
    z-index: -1;
    padding: 4px;
    border-radius: 0 6px 6px 0;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    border-left: none;
    font-size: 12px;
    width: max-content;
    transition: all 0.2s;
    opacity: 0;
    @media (max-width: 769px) {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      left: -8px;
      top: 50%;
      border-top: 1px solid #e6e7eb;
      border-left: 1px solid #e6e7eb;
      width: 16px;
      height: 16px;
      background: white;
      transform: translateY(-50%) rotate(-45deg);
      z-index: -1;
    }
  }
  &.router-link-exact-active {
    svg {
      stroke: #6764ff;

      &.svgFill {
        stroke: none;
        fill: #6764ff;
      }

      @media (max-width: 769px) {
        stroke: #80869a;

        &.svgFill {
          stroke: none;
          fill: #80869a;
        }
      }
    }
  }
  &:hover {
    color: #6764ff;
    svg {
      stroke: #6764ff;

      &.svgFill {
        stroke: none;
        fill: #6764ff;
      }
    }
  }
  &.resize {
    font-size: 0;
    &:hover {
      .VMenuProfileLink__help {
        opacity: 1;
        z-index: 1;
      }
    }
    svg {
      margin-right: 0;
    }

    .VMenuProfileLink__desc {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  &__desc {
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-size: 12px;

    @media screen and (max-width: 768px) {
      margin-right: 12px;

      + .vProfileMenu__arrow-right {
        margin-left: 12px;
      }
    }
  }

  &__cloud-circle {
    @media screen and (max-width: 768px) {
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      margin: 3px 0 0 6px;
      border-radius: 100%;
      background: #ec4e4e;
    }
  }
}
</style>
