const state = {
  modal: false,
}

const mutations = {
  SET_MODAL_POLITIC(state, payload) {
    state.modal = payload
  },
}
const actions = {
  CHANGE_MODAL_POLITIC({ commit, state }) {
    commit('SET_MODAL_POLITIC', !state.modal)
  },
}

const getters = {
  GET_MODAL_POLITIC(state) {
    return state.modal
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
