<template>
  <main class="signLayout">
    <slot />
    <vPreloader v-if="PRELOADER" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import vPreloader from '@/components/v-preloader'
export default {
  name: 'SignLayout',
  components: {
    vPreloader,
  },
  computed: {
    ...mapGetters('preloader', ['PRELOADER']),
  },
}
</script>

<style lang="scss">
.signLayout {
  background-color: #f7f8fa;
  min-height: 100vh;
}
</style>
