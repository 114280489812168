<template>
  <div
    class="vAuthentication"
    :class="{ active: GET_AUTHENTICATION_STATUS.status }"
  >
    <svg
      class="vAuthentication__ico"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 32C11.7565 32 7.68697 30.3144 4.68624 27.3138C1.68565 24.3132 0 20.2433 0 16C0 11.7567 1.68565 7.68697 4.68624 4.68624C7.68683 1.68565 11.7567 0 16 0C20.2433 0 24.313 1.68565 27.3138 4.68624C30.3144 7.68683 32 11.7567 32 16C31.9954 20.2421 30.3082 24.3089 27.3084 27.3083C24.3089 30.308 20.242 31.9953 16.0001 31.9999L16 32ZM16 3.19959C12.6051 3.19959 9.3493 4.54827 6.94913 6.94885C4.54854 9.34916 3.19986 12.605 3.19986 15.9997C3.19986 19.3945 4.54854 22.6504 6.94913 25.0506C9.34944 27.4512 12.6053 28.7999 16 28.7999C19.3947 28.7999 22.6507 27.4512 25.0509 25.0506C27.4515 22.6503 28.8001 19.3945 28.8001 15.9997C28.7961 12.6062 27.4464 9.35245 25.0466 6.95297C22.6471 4.55321 19.3934 3.20343 15.9999 3.19945L16 3.19959ZM17.5999 17.5998V9.60014C17.5999 9.02852 17.2951 8.5003 16.8001 8.21449C16.3051 7.92868 15.6949 7.92868 15.1999 8.21449C14.7049 8.5003 14.4001 9.02852 14.4001 9.60014V17.6005V17.6002C14.4001 18.1718 14.7049 18.7 15.1999 18.9859C15.6949 19.2717 16.3051 19.2717 16.8001 18.9859C17.2951 18.7 17.5999 18.1718 17.5999 17.6002V17.5998ZM16 24.0075V24.0078C15.5736 24.0078 15.1645 23.8382 14.8632 23.5369C14.5616 23.2353 14.3923 22.8263 14.3923 22.3998C14.3923 21.9734 14.5616 21.5646 14.8632 21.263C15.1645 20.9614 15.5735 20.7921 16 20.7921C16.4265 20.7921 16.8355 20.9614 17.1368 21.263C17.4384 21.5647 17.6077 21.9734 17.6077 22.3998C17.6064 22.826 17.4365 23.234 17.1352 23.5353C16.8341 23.8364 16.4259 24.0062 16 24.0078L16 24.0075Z"
        fill="#413DFF"
      />
    </svg>
    {{ GET_AUTHENTICATION_STATUS.msg }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VAuthentication',
  computed: {
    ...mapGetters('authentication', ['GET_AUTHENTICATION_STATUS']),
  },
}
</script>
<style lang="scss">
.vAuthentication {
  position: fixed;
  top: 0;
  left: 16px;
  width: calc(100% - 32px);
  background: #ececff;
  border: 1px solid #bdbcff;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  font-family: 'Commissioner';
  font-size: 16px;
  color: #010d35;
  z-index: 99999;
  transform: translateY(-100%);
  transition: transform 0.5s;
  &.active {
    transform: translateY(22px);
  }
  &__ico {
    margin-right: 8px;
  }
}
</style>
