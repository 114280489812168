import axios from 'axios'

const state = () => ({
  requisites: {},
})

const mutations = {
  SET_REQUISITES(state, requisites) {
    state.requisites = requisites
  },
}

const actions = {
  async FETCH_REQUISITES({ commit }, token) {
    try {
      const respone = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/shop/requisites`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_REQUISITES', respone.data.result)
    } catch (e) {
      console.log(e)
    }
  },

  async SAVE_REQUISITES({ commit }, payload) {
    try {
      const respone = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/shop/requisites`,
        payload.data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  REQUISITES(state) {
    return state.requisites
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
