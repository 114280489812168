import axios from 'axios'
const state = {
  loader: {
    gif: '',
    size: '',
    code_color: '',
  },
}

const mutations = {
  SET_LOADER(state, payload) {
    state.loader = payload
  },
}
const actions = {
  CHANGE_LOADER({ commit }, payload) {
    commit('SET_LOADER', payload)
  },
  async SAVE_LOADER({ state, commit }, payload) {
    await axios
      .post(
        `${process.env.VUE_APP_BASE_URL}/shop/loader/${payload.merchantId}`,
        payload.data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      .then(() => {
        commit('SET_LOADER', payload.data)
      })
  },
  async FETCH_LOADER({ state, commit }, payload) {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/shop/loader/${payload.merchantId}`,
      {
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      }
    )
    const { gif, size, code_color } = response.data.result
    commit('SET_LOADER', { gif, size, code_color })
  },
}

const getters = {
  GET_LOADER: (state) => state.loader,
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
