import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
const state = {
  session: null,
  guest: null,
  selectedGuestId: null,
}

const mutations = {
  SET_SESSION(state, session) {
    state.session = session
  },
  SET_GUEST(state, guest) {
    state.guest = guest
  },
  SET_SELECTED_GUEST_ID(state, id) {
    state.selectedGuestId = id
  },
}

const actions = {}

const getters = {
  session: (state) => state.session,
  guest: (state) =>
    !isNaN(parseInt(state.guest))
      ? parseInt(state.guest)
      : parseInt(cookies.get('guest')),
  selectedGuest: (state) => state.selectedGuestId,
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
