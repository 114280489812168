import axios from 'axios'
import logs from './logs'

const state = () => ({})

const mutations = {}

const actions = {
  async EXPORT_STATISTIC({}, payload) {
    console.log('EXPORT_STATISTIC-payload', payload)
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/statistic/export-day-statistic`,
        { date: payload.date },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', payload.date + '.xlsx')
      document.body.appendChild(link)
      link.click()
      console.log('EXPORT_STATISTIC', response)
    } catch (e) {
      console.log(e)
    }
  },
  async EXPORT_BALANCE({}, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/statistic/waiter-balance-sheet`,
        { date: payload.date },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', payload.date + '.xlsx')
      document.body.appendChild(link)
      link.click()
      console.log('EXPORT_BALANCE', response)
    } catch (e) {
      console.log(e)
    }
  },
  async EXPORT_STATISTIC_PERIOD({}, payload) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/statistic/export-statistics-for-period?from=${payload.from}&before=${payload.before}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )

      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `statistics-for-period?from=${payload.from}&before=${payload.before}` +
          '.xlsx'
      )
      document.body.appendChild(link)
      link.click()
      console.log('EXPORT_STATISTIC', response)
    } catch (e) {
      console.log('e', e)
    }
  },
  async EXPORT_CONVERSION_STATISTICS({}, payload) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/statistic/export-conversion-statistics-for-period?from=${payload.from}&before=${payload.before}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )

      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `conversion-statistics-for-period?from=${payload.from}&before=${payload.before}` +
          '.xlsx'
      )
      document.body.appendChild(link)
      link.click()
      console.log('EXPORT_CONVERSION_STATISTICS', response)
    } catch (e) {
      console.log('e', e)
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
