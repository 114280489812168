<template>
  <main class="emptyLayout">
    <slot />
    <vAuthentication />
    <vLandingPoliticModal
      v-show="GET_MODAL_POLITIC"
      @togglePoliticModal="togglePoliticModal"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import vAuthentication from '@/components/v-authentication'
import vLandingPoliticModal from '@/components/landing/v-landing-politic-modal'
export default {
  name: 'EmptyLayout',
  components: {
    vAuthentication,
    vLandingPoliticModal,
  },
  computed: {
    ...mapGetters('modalPolitic', ['GET_MODAL_POLITIC']),
  },
}
</script>
