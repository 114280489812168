<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <line
      x1="10"
      y1="7"
      x2="17"
      y2="7"
      stroke-width="2"
    />
    <line
      x1="10"
      y1="11"
      x2="17"
      y2="11"
      stroke-width="2"
    />
    <line
      x1="4"
      y1="2"
      x2="4"
      y2="22"
      stroke-width="2"
    />
    <path
      d="M7 3H19C19.5523 3 20 3.44772 20 4V20C20 20.5523 19.5523 21 19 21H7V3Z"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconCard',
}
</script>

<style lang="scss"></style>
