<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M5.63599 5.63604C6.89466 4.37737 8.49831 3.5202 10.2441 3.17294C11.99 2.82567 13.7996 3.0039 15.4441 3.68509C17.0886 4.36628 18.4942 5.51983 19.4832 6.99987C20.4721 8.47991 20.9999 10.22 20.9999 12C20.9999 13.78 20.4721 15.5201 19.4832 17.0001C18.4942 18.4802 17.0886 19.6337 15.4441 20.3149C13.7996 20.9961 11.99 21.1743 10.2441 20.8271C8.49831 20.4798 6.89466 19.6226 5.63599 18.364"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.499 8.5H10.051C9.72839 8.5 9.41439 8.604 9.15557 8.79657C8.89676 8.98913 8.70692 9.26001 8.61423 9.569L7.56423 13.069C7.49704 13.293 7.48315 13.5296 7.52366 13.7599C7.56417 13.9902 7.65796 14.2078 7.79755 14.3954C7.93713 14.583 8.11865 14.7354 8.3276 14.8404C8.53655 14.9453 8.76714 15 9.00098 15H14.449C14.7716 15 15.0856 14.896 15.3444 14.7034C15.6032 14.5109 15.793 14.24 15.8857 13.931L16.9357 10.431C17.0029 10.207 17.0168 9.97044 16.9763 9.74014C16.9358 9.50984 16.842 9.2922 16.7024 9.1046C16.5628 8.91699 16.3813 8.76462 16.1724 8.65965C15.9634 8.55467 15.7328 8.5 15.499 8.5V8.5Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.8999 11.25L11.8999 12.25L10.8999 11.25"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 9H3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 15H3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 12H2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconMailing',
}
</script>
