<template>
  <header class="vProfileHeader">
    <div class="vProfileHeader__container">
      <div
        class="vProfileHeader__back"
        @click="CHAANGE_ACTIVE_PROFILE_MENU(null)"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="vProfileHeader__arrow-left"
        >
          <path
            d="M8 10L12 14L16 10"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <ul class="vProfileHeader__breadcrumbs">
        <li class="vProfileHeader__breadcrumbs-item">
          {{ nameOfPage }}
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'VProfileHeader',
  computed: {
    ...mapGetters('menu', ['GET_MENU_PROFILE']),
    nameOfPage() {
      switch (this.$route.name) {
        case 'profile':
          return 'Профиль'
        case 'Integration':
          return 'Интеграции'
        case 'Waiter':
          return 'Официанты'
        case 'Customization':
          return 'Кастомизация'
        case 'Qr':
          return 'Столики и QR-коды'
        case 'History':
          return 'История операций'
        case 'Req':
          return 'Реквизиты компании'
        case 'MenuAdmin':
          return 'Меню'
        case 'telegram':
          return 'Настройка уведомлений'
        case 'Logs':
          return 'Логи/доступы'
        case 'OrderHistory':
          return 'История операций'
        case 'plug':
          return 'Заглушка'
        case 'mailing':
          return 'Рассылка'
        case 'inaccessibility':
          return 'Недоступность кассы'
        case 'Analytics':
          return 'Аналитика'
        default:
          return 'Профиль'
      }
    },
  },
  methods: {
    ...mapActions('menu', ['CHAANGE_ACTIVE_PROFILE_MENU']),
  },
}
</script>

<style lang="scss">
.vProfileHeader {
  position: relative;
  z-index: 2;
  background: #f7f8fa;
  height: 82px;
  border-bottom: 1px solid #edeef1;
  margin-bottom: 35px;
  @media (max-width: 769px) {
    height: 70px;
    margin-bottom: 0;
    border: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: 24px;
  }
  &__container {
    padding: 0 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 769px) {
      padding: 0 18px;
    }
  }
  &__back {
    transform: rotate(180deg);
    display: none;
    @media (max-width: 769px) {
      display: block;
    }
  }
  &__breadcrumbs {
    display: flex;
    align-items: baseline;
    @media (max-width: 769px) {
      margin-right: auto;
      margin-left: 12px;
      width: calc(100% - 24px);
    }
    &-item {
      color: #010d35;
      font-weight: 600;
      font-size: 20px;
      @media (max-width: 769px) {
        width: 100%;
        text-align: center;
      }
    }
  }
  &__arrow-left {
    margin-left: auto;
    transform: rotate(-90deg);
  }
}
</style>
