<template>
  <div class="vCookies">
    <div class="vCookies__container">
      <p>
        Мы используем файлы cookie для вашего удобства пользования сайтом.
        <a
          href="#"
          class="vCookies__link"
          @click="togglePoliticModal"
        >Подробнее</a>
      </p>
      <button
        class="vCookies__btn"
        @click="onAcceptCookies"
      >
        Я согласен
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'VCookies',
  emits: ['onAcceptCookies', 'togglePoliticModal'],
  methods: {
    ...mapActions('modalPolitic', ['CHANGE_MODAL_POLITIC']),
    onAcceptCookies() {
      this.$emit('onAcceptCookies')
    },
    togglePoliticModal() {
      const cookieEl = document.getElementById('cookie')
      this.CHANGE_MODAL_POLITIC()
      setTimeout(() => {
        cookieEl.scrollIntoView(true)
      }, 10)
    },
  },
}
</script>
<style lang="scss">
.vCookies {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 12px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fbfbfc;
  background: rgba(251, 251, 252, 0.5);
  backdrop-filter: blur(4px);
  z-index: 30;
  @media (max-width: 769px) {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
    background: rgba(251, 251, 252, 0.8);
  }
  p {
    font-family: 'PTRootUI';
  }
  &__container {
    width: 100%;
    max-width: 1200px;
    padding: 0 36px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 769px) {
      padding: 0 20px;
    }
  }
  &__link {
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #fbfbfc;
    text-decoration: underline;
    @media (max-width: 769px) {
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  &__btn {
    padding: 8px 15px 7px 16px;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #fbfbfc;
    border: 2px solid #fbfbfc;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    @media (max-width: 769px) {
      flex-shrink: 0;
      padding: 10px 7px 8px 8px;
      margin-left: 12px;
      font-size: 12px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.8);
      border: 2px solid rgba(0, 0, 0, 0.8);
    }
  }
}
</style>
