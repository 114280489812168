const state = {
  layout: 'default-layout',
}

const mutations = {
  SET_LAYOUT(state, payload) {
    state.layout = payload
  },
}

const actions = {}

const getters = {
  LAYOUT(state) {
    return state.layout
  },
}

export default {
  mutations,
  state,
  actions,
  getters,
}
