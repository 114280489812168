import axios from 'axios'

const state = () => ({
  shop_error: '',
})

const mutations = {
  SET_SHOP_ERRORS(state, msg) {
    state.shop_error = msg
  },
  CLEAR_SHOP_ERRORS(state) {
    state.shop_error = ''
  },
}

const actions = {
  async CHECK_SHOPS({ commit }, payload) {
    commit('CLEAR_SHOP_ERRORS')
    try {
      const query = {
        name: payload,
      }
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/shop/examination-name`,
        query
      )
      if (!response.data.success) {
        commit('SET_SHOP_ERRORS', 'Заведение с таким именем уже существует!')
      }
    } catch (e) {
      console.log(e)
      commit('SET_SHOP_ERRORS', 'Неверное название заведенеия')
    }
  },
  CLEAR_SHOP_ERRORS({ commit }) {
    commit('CLEAR_SHOP_ERRORS')
  },
}

const getters = {
  SHOP_ERROR(state) {
    return state.shop_error
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
