import axios from 'axios'

const actions = {
  async FETCH_EXTERNAL_REVIEWS(_, token) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/external-reviews`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return res.data.result
    } catch (e) {
      console.log('====================================')
      console.log('FETCH_EXTERNAL_REVIEWS_ERROR', e)
      console.log('====================================')
    }
  },

  async SAVE_EXTERNAL_REVIEWS(_, payload) {
    try {
      const res = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/external-reviews`,
        payload.query,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log('====================================')
      console.log(
        'SAVE_EXTERNAL_REVIEWS_ERROR',
        JSON.stringify(e.response.data, null, 2)
      )
      console.log('====================================')
      if (e.response.data.message === 'The given data was invalid.') {
        return e.response.data
      }
    }
  },
}

export default {
  namespaced: true,
  actions,
}
