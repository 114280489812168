<template>
  <main class="landingLayout">
    <vLandingHeader />
    <slot />
    <vLandingFooter
      @togglePoliticModal="togglePoliticModal"
      @togglePayModal="togglePayModal"
    />
    <vLandingPoliticModal
      v-show="GET_MODAL_POLITIC"
      @togglePoliticModal="togglePoliticModal"
    />
    <vLandingPayModal
      v-if="payModal"
      @togglePayModal="togglePayModal"
    />
    <vCookies
      v-if="!cookiesAccept"
      @onAcceptCookies="onAcceptCookies"
      @togglePoliticModal="togglePoliticModal"
    />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vLandingHeader from '@/components/landing/v-landing-header'
import vLandingFooter from '@/components/landing/v-landing-footer'
import vLandingPoliticModal from '@/components/landing/v-landing-politic-modal'
import vLandingPayModal from '@/components/landing/v-landing-pay-modal'
import vCookies from '@/components/v-cookies'
export default {
  name: 'LandingLayout',
  components: {
    vLandingHeader,
    vLandingFooter,
    vLandingPoliticModal,
    vLandingPayModal,
    vCookies,
  },
  data() {
    return {
      politicModal: false,
      payModal: false,
      cookiesAccept: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('modalPolitic', ['GET_MODAL_POLITIC']),
  },
  mounted() {
    this.GET_USER_DATA(this.GET_AUTHENTIFICATED)
    this.getCookie()
  },
  methods: {
    ...mapActions('user', ['GET_USER_DATA']),
    togglePoliticModal() {
      this.politicModal = !this.politicModal
    },
    togglePayModal() {
      this.payModal = !this.payModal
    },
    getCookie() {
      const cookie = this.$cookies.get('accept')
      if (cookie !== null) {
        this.cookiesAccept = true
      }
    },
    onAcceptCookies() {
      this.$cookies.set('accept', 'true')
      this.cookiesAccept = true
    },
  },
}
</script>

<style lang="scss">
.landingLayout {
  background: #14141b;
}
</style>
