import axios from 'axios'

const state = {
  error_feedback: false,
  success: false,
}

const mutations = {
  SET_ERROR(state, payload) {
    state.error_feedback = payload
  },
  CLEAR_FEEDBACK_ERROR(state, payload) {
    state.error_feedback = ''
  },
  SET_SUCCESS(state, payload) {
    state.success = payload
  },
}

const actions = {
  async FEEDBACK({ commit }, payload) {
    commit('CLEAR_FEEDBACK_ERROR')
    try {
      const req = {}
      Object.keys(payload).forEach((i) => {
        req[i] = payload[i]
      })

      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/services/back-call`,
        req
      )
      if (response.data.success) {
        commit('SET_SUCCESS', true)
        setTimeout(() => {
          commit('SET_SUCCESS', false)
        }, 2000)
      }
    } catch (e) {
      console.log(e)
    }
  },
  CLOSE_ACCESS({ commit }) {
    commit('SET_SUCCESS', false)
  },
}

const getters = {
  GET_FEEDBACK_ERROR(state) {
    return state.error_feedback
  },
  GET_FEEDBACK_SUCCESS(state) {
    return state.success
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
