<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M13.909 10.0909C14.9634 11.1453 14.9634 12.8548 13.909 13.9092C12.8546 14.9637 11.145 14.9637 10.0906 13.9092C9.0362 12.8548 9.0362 11.1453 10.0906 10.0909C11.145 9.03644 12.8546 9.03644 13.909 10.0909"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.25036 12C5.25036 12.297 5.27736 12.594 5.31336 12.882L3.72536 14.124C3.37336 14.4 3.27736 14.893 3.50136 15.28L4.91336 17.723C5.13636 18.11 5.61036 18.273 6.02536 18.107L7.44736 17.536C7.72836 17.423 8.04136 17.468 8.29336 17.635C8.51336 17.781 8.74136 17.915 8.97736 18.035C9.24736 18.172 9.44336 18.417 9.48636 18.717L9.70336 20.23C9.76636 20.672 10.1454 21 10.5914 21H13.4084C13.8544 21 14.2334 20.672 14.2964 20.23L14.5134 18.718C14.5564 18.418 14.7544 18.171 15.0254 18.035C15.2604 17.917 15.4874 17.784 15.7064 17.639C15.9604 17.471 16.2744 17.423 16.5564 17.537L17.9754 18.107C18.3894 18.273 18.8634 18.11 19.0874 17.723L20.4994 15.28C20.7234 14.893 20.6274 14.399 20.2754 14.124L18.6874 12.882C18.7234 12.594 18.7504 12.297 18.7504 12C18.7504 11.703 18.7234 11.406 18.6874 11.118L20.2754 9.876C20.6274 9.6 20.7234 9.107 20.4994 8.72L19.0874 6.277C18.8644 5.89 18.3904 5.727 17.9754 5.893L16.5564 6.463C16.2744 6.576 15.9604 6.529 15.7064 6.361C15.4874 6.216 15.2604 6.083 15.0254 5.965C14.7544 5.829 14.5564 5.582 14.5134 5.282L14.2974 3.77C14.2344 3.328 13.8554 3 13.4094 3H10.5924C10.1464 3 9.76736 3.328 9.70436 3.77L9.48636 5.284C9.44336 5.583 9.24636 5.829 8.97736 5.966C8.74136 6.086 8.51336 6.221 8.29336 6.366C8.04036 6.532 7.72736 6.577 7.44636 6.464L6.02536 5.893C5.61036 5.727 5.13636 5.89 4.91336 6.277L3.50136 8.72C3.27736 9.107 3.37336 9.601 3.72536 9.876L5.31336 11.118C5.27736 11.406 5.25036 11.703 5.25036 12V12Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconDetal',
}
</script>
