const state = {
  preloader: true,
  text: '',
}

const mutations = {
  SET_PRELOADER(state, payload) {
    state.preloader = payload
  },
  SET_TEXT(state, payload) {
    state.text = payload
  },
}

const actions = {
  TOGGLE_PRELOADER({ commit }, payload) {
    commit('SET_PRELOADER', payload)
  },
  SET_TEXT_PRELOADER({ commit }, payload) {
    commit('SET_TEXT', payload)
  },
}

const getters = {
  PRELOADER(state) {
    return state.preloader
  },
  PRELOADER_TEXT(state) {
    return state.text
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
