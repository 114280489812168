<template>
  <nav
    v-show="GET_MENU_PROFILE == null"
    class="vProfileMenu"
    :class="{ resize: resize }"
  >
    <div
      v-if="!resize"
      class="vProfileMenu__overlay"
      @click="resizeMenu"
    />
    <router-link
      to="/profile"
      class="vProfileMenu__logo"
    >
      <img
        src="~@/assets/images/logo-tapper.png"
        alt="tapper"
      >
    </router-link>
    <router-link
      to="/profile"
      class="vProfileMenu__logo-small"
    >
      <img
        src="~@/assets/images/logo-small-tapper.png"
        alt="tapper"
      >
    </router-link>
    <div class="vProfileMenu__info">
      <div class="vProfileMenu__user">
        <span class="vProfileMenu__shop">{{
          USER_DATA && USER_DATA.shops_list?.name
        }}</span>
        <span class="vProfileMenu__name">{{
          USER_DATA.display_name ?? USER_DATA.name
        }}</span>
      </div>
      <div class="vProfileMenu__controlls">
        <span
          class="vProfileMenu__exit"
          @click="logout"
        ><svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 8.98001V10.98M1 14V16.042C1 17.147 1.895 18.042 3 18.042H6M1 6.00001V4.00001C1 2.89501 1.895 2.00001 3 2.00001H6M1 10H6M6 10L4 12M6 10L4 8.00001M11.163 19L15.607 18.012C16.421 17.832 17 17.11 17 16.277V3.76601C17 2.93301 16.421 2.21101 15.608 2.03101L11.164 1.04301C10.053 0.796014 9 1.64101 9 2.77901V17.265C9 18.402 10.053 19.247 11.163 19Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        </span>
        <div
          class="vProfileMenu__resize"
          @click="resizeMenu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17 21H7C4.791 21 3 19.209 3 17L3 7C3 4.791 4.791 3 7 3L17 3C19.209 3 21 4.791 21 7V17C21 19.209 19.209 21 17 21Z"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 12L8.5 12"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.5 15L13 15"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.5 9L13 9"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 9.5L7.5 12L10 14.5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <h2 class="vProfileMenu__title-mobile">
      Главное меню
    </h2>

    <!-- Список ссылок -->
    <div
      v-if="hasUserRole"
      class="vProfileMenu__list"
    >
      <template
        v-for="link in links"
        :key="link.label"
      >
        <VMenuProfileLink
          v-if="link.linkName !== 'logs'"
          :label="link.label"
          :desc="link.linkName === 'cloud' ? descriptionCloudTips : link.desc"
          :active-link="GET_MENU_PROFILE"
          :link-name="link.linkName"
          :roles="link.roles"
          :resize="resize"
          :current-role="USER_DATA.role_user"
          @selectMenu="selectMenu"
        >
          <component
            :is="`VIcon${
              link.linkName === 'cloud' ? iconCloudTips : link.icon
            }`"
          />
        </VMenuProfileLink>
        <VLogsMenuLink
          v-if="link.linkName === 'logs' && USER_DATA.role_user === 'support'"
          :label="link.label"
          :active-link="GET_MENU_PROFILE"
          :link-name="link.linkName"
          :roles="link.roles"
          :resize="resize"
          :current-role="USER_DATA.role_user"
          :token="GET_AUTHENTIFICATED"
          @togglePreloader="togglePreloader"
          @selectMenu="selectMenu"
        />
      </template>
    </div>
    <p class="vProfileMenu__copyright">
      &copy; powered by tapper 2021
    </p>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VMenuProfileLink from '@/components/menuProfile/v-menu-profile-link'
import VLogsMenuLink from '@/components/logs/v-logs-menu-link'
import VIconProfile from '@/assets/images/icons/v-icon-profile.vue'
import VIconIntegration from '@/assets/images/icons/v-icon-integration.vue'
import VIconWaiter from '@/assets/images/icons/v-icon-waiter.vue'
import VIconQr from '@/assets/images/icons/v-icon-qr.vue'
import VIconHistory from '@/assets/images/icons/v-icon-history.vue'
import VIconReq from '@/assets/images/icons/v-icon-req.vue'
import VIconCard from '@/assets/images/icons/v-icon-card.vue'
import VIconMenuAdmin from '@/assets/images/icons/v-icon-menu-admin.vue'
import VIconCustomization from '@/assets/images/icons/v-icon-customization'
import VIconCloudCard from '@/assets/images/icons/v-icon-cloud-card'
import VIconPlug from '@/assets/images/icons/v-icon-plug'
import VIconMailing from '@/assets/images/icons/v-icon-mailing'
import VIconCloud from '@/assets/images/icons/v-icon-cloud'
import VIconOrderHistory from '@/assets/images/icons/v-icon-order-history'
import VIconTelegram from '@/assets/images/icons/v-icon-telegram'
import VIconInaccessibility from '@/assets/images/icons/v-icon-inaccessibility'
import VIconAnalytics from '@/assets/images/icons/v-icon-analytics'

export default {
  name: 'VProfileMenu',
  components: {
    VMenuProfileLink,
    VLogsMenuLink,
    VIconIntegration,
    VIconProfile,
    VIconWaiter,
    VIconQr,
    VIconHistory,
    VIconReq,
    VIconCard,
    VIconMenuAdmin,
    VIconCustomization,
    VIconCloud,
    VIconCloudCard,
    VIconOrderHistory,
    VIconPlug,
    VIconMailing,
    VIconTelegram,
    VIconInaccessibility,
    VIconAnalytics,
  },
  data() {
    return {
      resize: true,
      links: [
        {
          label: 'Профиль',
          icon: 'Profile',
          linkName: 'profile',
          roles: ['all'],
        },
        {
          label: 'Интеграции',
          icon: 'Integration',
          linkName: 'integration',
          roles: ['manager'],
        },
        {
          label: 'Официанты',
          icon: 'Waiter',
          linkName: 'waiter',
          roles: ['manager'],
        },
        {
          label: 'Кастомизация',
          icon: 'Customization',
          linkName: 'customization',
          roles: ['manager'],
        },
        {
          label: 'Столики и QR-коды',
          icon: 'Qr',
          linkName: 'qr',
          roles: ['manager'],
        },
        {
          label: 'История операций',
          icon: 'History',
          linkName: 'history',
          roles: ['manager'],
        },
        {
          label: 'История операций',
          icon: 'History',
          linkName: 'history',
          role: 'manager',
        },
        {
          label: 'Реквизиты компании',
          icon: 'Req',
          linkName: 'req',
          roles: ['manager'],
        },
        // {
        //   label: "Виртуальная карта",
        //   icon: "Card",
        //   linkName: "virtualCard",
        //   roles: ["waiter"],
        // },
        // {
        //   label: 'Cloud Tips',
        //   icon: 'Cloud',
        //   linkName: 'cloud',
        //   roles: ['waiter'],
        //   desc: 'Карта не привязана',
        // },
        {
          label: 'Логи/доступы',
          icon: 'Detal',
          linkName: 'logs',
          roles: ['support'],
        },
        {
          label: 'Меню',
          icon: 'MenuAdmin',
          linkName: 'menu-admin',
          roles: ['manager'],
        },
        {
          label: 'История операций',
          icon: 'OrderHistory',
          linkName: 'order-history',
          roles: ['support'],
        },
        {
          label: 'Заглушка',
          icon: 'Plug',
          linkName: 'plug',
          roles: ['support'],
        },
        {
          label: 'Рассылка',
          icon: 'Mailing',
          linkName: 'mailing',
          roles: ['support'],
        },
        {
          label: 'Настройка уведомлений',
          icon: 'Telegram',
          linkName: 'telegram',
          roles: ['manager'],
        },
        {
          label: 'Недоступность кассы',
          icon: 'Inaccessibility',
          linkName: 'inaccessibility',
          roles: ['support'],
        },
        {
          label: 'Аналитика',
          icon: 'Analytics',
          linkName: 'analytics',
          roles: ['support'],
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['USER_DATA', 'GET_AUTHENTIFICATED', 'CLOUD_DATA']),
    ...mapGetters('menu', ['GET_MENU_PROFILE']),
    hasWaiterCloudVerification() {
      return (
        (this.USER_DATA.role_user === 'waiter' &&
          this.CLOUD_DATA?.card_last_four) ||
        false
      )
    },
    descriptionCloudTips() {
      return this.hasWaiterCloudVerification
        ? `Ваша карта *${this.CLOUD_DATA.card_last_four}`
        : 'Карта не привязана'
    },
    iconCloudTips() {
      return this.hasWaiterCloudVerification
        ? 'CloudCard'
        : window.matchMedia('(max-width: 769px)').matches
        ? 'CloudCard'
        : 'Cloud'
    },
    hasUserRole() {
      return this.USER_DATA?.role_user
    },
  },
  watch: {
    USER_DATA() {
      if (window.innerWidth > 769 || window.innerWidth < 770 && this.USER_DATA.role_user === 'waiter') {
        this.CHAANGE_ACTIVE_PROFILE_MENU({ code: 'profile', name: 'Профиль' })
      } else {
        this.CHAANGE_ACTIVE_PROFILE_MENU(null)
      }
    }
  },
  async mounted() {
    await this.GET_CLOUD_DATA(this.GET_AUTHENTIFICATED)

    if (window.innerWidth > 769 || window.innerWidth < 770 && this.USER_DATA.role_user === 'waiter') {
      this.CHAANGE_ACTIVE_PROFILE_MENU({ code: 'profile', name: 'Профиль' })
    } else {
      this.CHAANGE_ACTIVE_PROFILE_MENU(null)
    }
  },
  methods: {
    ...mapActions('menu', ['CHAANGE_ACTIVE_PROFILE_MENU']),
    ...mapActions('user', ['LOGOUT', 'GET_CLOUD_DATA']),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    selectMenu(num) {
      if (
        this.GET_MENU_PROFILE === null ||
        this.GET_MENU_PROFILE.code !== num.code
      ) {
        // this.TOGGLE_PRELOADER(true)
        this.CHAANGE_ACTIVE_PROFILE_MENU(num)
      }
    },
    togglePreloader(val) {
      this.TOGGLE_PRELOADER(val)
    },
    async logout() {
      await this.LOGOUT()
      this.$router.push('/')
    },
    resizeMenu() {
      this.resize = !this.resize
    },
  },
}
</script>
<style lang="scss">
.vProfileMenu {
  // position: absolute;
  left: 0;
  top: 0;
  width: 335px;
  height: 100%;
  border: 1px solid #e6e7eb;
  z-index: 99999;
  background: #fff;
  padding: 28px 24px;
  height: 100vh;
  position: fixed;
  @media (min-width: 769px) {
    display: block !important;
  }
  @media (max-width: 769px) {
    background: #f7f8fa;
    width: 100% !important;
    position: fixed;
    z-index: 30;
    overflow: auto;
    //height: calc(100vh - 170px);
    height: 100%;
    border-bottom: none;
  }
  &__logo {
    display: inline-block;
    margin-bottom: 24px;
    @media (max-width: 769px) {
      display: none;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    @media (max-width: 769px) {
      padding: 12px 16px 12px 20px;
      margin-bottom: 24px !important;
      border: 1px solid #e6e7eb;
      border-radius: 11px;
      background: #fff;
      width: 100%;
    }
  }
  &__user {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    @media (max-width: 769px) {
      display: flex !important;
    }
  }
  &__shop {
    font-size: 13px;
    color: #80869a;
    line-height: 18px;
  }
  &__name {
    font-size: 13px;
    line-height: 18px;
  }
  &__controlls {
    display: flex;
    margin-left: auto;
    align-items: center;
    svg {
      stroke: #80869a;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        stroke: #6764ff;
      }
    }
  }
  &__exit {
    margin-right: 13px;
    @media (max-width: 769px) {
      margin-right: 0;
      display: block !important;
    }
  }
  &__resize {
    @media (max-width: 769px) {
      display: none;
    }
  }
  &__title-mobile {
    display: none;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #010d35;
    @media (max-width: 769px) {
      display: block;
      width: 100%;
    }
  }
  &__list {
    margin: 8px 0 0 0;
    @media (max-width: 769px) {
      padding: 0 12px;
      margin-bottom: 24px;
      background: #ffffff;
      border: 1px solid #e6e7eb;
      border-radius: 11px;
      width: 100%;
    }
  }
  &__ico {
    stroke: #80869a;
    margin-right: 12px;
    transition: stroke 0.2s;
    &--plug {
      stroke: none;
      fill: #80869a;
    }

    &.svgFill {
      stroke: none;
      fill: #80869a;
    }

    @media (max-width: 769px) {
      stroke: #80869a;
      margin-right: 12px !important;

      &.svgFill {
        stroke: none;
        fill: #80869a;
      }
    }
  }
  &__arrow-right {
    margin-left: auto;
    transform: rotate(-90deg);
    @media (max-width: 769px) {
      display: block !important;
    }
  }
  &__copyright {
    display: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #80869a;
    @media (max-width: 769px) {
      display: block;
    }
  }
  &__logo-small {
    display: none;
    margin-bottom: 27px;
    text-align: center;
    @media (max-width: 769px) {
      display: none !important;
    }
  }
  &__overlay {
    position: fixed;
    left: 335px;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &.resize {
    width: 72px;
    padding: 28px 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .vProfileMenu__logo,
    .vProfileMenu__exit,
    .vProfileMenu__arrow-right,
    .vProfileMenu__user {
      display: none;
    }
    .vProfileMenu__resize {
      transform: rotate(180deg);
    }
    .vProfileMenu__logo-small {
      display: block;
    }
    .vProfileMenu__controlls {
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 100px;
    }
    @media screen and (max-height: 650px) {
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
}
</style>
