import axios from 'axios'

const state = {
  transactions: null,
}

const mutations = {
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions
  },
}

const actions = {
  async FETCH_TRANSACTIONS({ commit }, payload) {
    commit('SET_TRANSACTIONS', null)
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/transactions`,
        {
          params: {
            date_start: payload.date_start,
            date_end: payload.date_end,
            count: payload.count,
            page: payload.page,
            shop_id: payload.shop_id,
            status: payload.status,
          },
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      let calculatingTotalAmount = 0
      let calculatingFinalTips = 0

      const totalAmount = response.data.total_sum

      // calculatingTotalAmount = totalAmount.reduce(
      //   (acc, item) => acc + item.sum,
      //   0
      // )
      // calculatingFinalTips = totalAmount.reduce(
      //   (acc, item) => acc + item.tips,
      //   0
      // )

      if (totalAmount) {
        if (Array.isArray(totalAmount)) {
          calculatingTotalAmount = totalAmount.reduce(
            (acc, item) => acc + item.sum,
            0
          )
          calculatingFinalTips = totalAmount.reduce(
            (acc, item) => acc + item.tips,
            0
          )
        } else {
          calculatingTotalAmount = totalAmount.sum
          calculatingFinalTips = totalAmount.tips
        }
      }

      const d = new Date(),
        month =
          '' + parseInt(d.getMonth() + 1) < 10
            ? '0' + (d.getMonth() + 1)
            : d.getMonth() + 1,
        day = '' + parseInt(d.getDate()) < 10 ? '0' + d.getDate() : d.getDate(),
        year = d.getFullYear()

      const today = `${year}-${month}-${day}`

      let items = response.data.data.items
      const waiters = response.data.data.waiters

      if (items) {
        items.forEach((i, idx) => {
          waiters.forEach((j) => {
            if (i.info && i.info.waiter_id) {
              if (i.info.waiter_id === j.id) {
                items[idx].info.waiter = j
              }
            }
          })
        })

        console.log('items', items)

        const total_sum = response.data.total_sum
        const newArr = []

        if (Array.isArray(total_sum)) {
          console.log('total_sum is array')
          total_sum.forEach((i) => {
            const itemsArr = items.filter((j) => j.date === i.day)
            console.log('itemsArr', itemsArr)
            if (itemsArr.length) {
              const convertDateItemsArr = itemsArr.map((k) => {
                const dateArr = k.date.split('-')
                dateArr.reverse()
                const newDateArr = dateArr.join('.')
                const newObj = { ...k, date: newDateArr }
                return newObj
              })
              newArr.push({
                ...i,
                day:
                  !payload.date_start.length && i.day === today
                    ? 'Сегодня'
                    : i.day,
                items: [...convertDateItemsArr],
              })
            }
          })
        } else {
          const itemsArr = items.filter((j) => j.date === total_sum.day)
          const convertDateItemsArr = itemsArr.map((k) => {
            const dateArr = k.date.split('-')
            dateArr.reverse()
            const newDateArr = dateArr.join('.')
            const newObj = { ...k, date: newDateArr }
            return newObj
          })
          newArr.push({
            ...total_sum,
            day:
              !payload.date_start.length && total_sum.day === today
                ? 'Сегодня'
                : total_sum.day,
            items: [...convertDateItemsArr],
          })
        }

        console.log('newArr', newArr)

        const newElements = newArr.map((i) => {
          const dayArray = i.day.split('-')
          return { ...i, day: `${dayArray[2]}.${dayArray[1]}.${dayArray[0]}` }
        })

        const data = {
          current_page: response.data.data.current_page,
          last_page: response.data.data.last_page,
          filter: response.data.data.filter.status,
          elements: newElements.reverse(),
        }

        commit('SET_TRANSACTIONS', {
          ...data,
          calculatingTotalAmount: calculatingTotalAmount,
          calculatingFinalTips: calculatingFinalTips,
        })
      } else {
        commit('SET_TRANSACTIONS', null)
      }
    } catch (e) {
      console.log(e)
    }
  },

  // async CLEAR_TRANSACTIONS({ commit }) {
  //   commit('SET_TRANSACTIONS', null)
  // },

  // Перевести в заведение
  async SAFE_PAYMENT({}, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/safepayment/${payload.id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response SAFE_PAYMENT', response)
    } catch (e) {
      console.log(e)
    }
  },

  // Перевести физ лицу
  async SAFE_CLOSE_DEAL({}, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/safeclosedeal/${payload.id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response SAFE_CLOSE_DEAL', response)
    } catch (e) {
      console.log(e)
    }
  },

  async REVERSE_TRANSACTIONS({}, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/transactions/reverse/${payload.id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('response', response)
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_TRANSACTIONS(state) {
    return state.transactions
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
